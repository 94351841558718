import { useStoreState } from 'easy-peasy';
import Branch from 'branch-sdk';

export const HUMAN_LINK = 'https://humantelligence.app.link';

const useAppLinks = () => {
  const branchData = useStoreState(store => store.branch.data);

  const handleStores = async store_url => {
    if (!!branchData && !!branchData.referring_link) {
      const linkData = {
        campaign: branchData['~campaign'],
        channel: branchData['~channel'],
        feature: branchData['~feature'],
        marketing: branchData['~marketing'],
        data: {
          name: branchData.name,
          $marketing_title: branchData.$marketing_title,
          $og_title: branchData.$og_title,
          $og_description: branchData.$og_description,
          $og_image_url: branchData.$og_image_url,
          $android_url:
            'https://play.google.com/store/apps/details?id=com.humantelligence',
          $canonical_url: 'https://humantelligence.io',
          $desktop_url:
            store_url === 'google_play'
              ? 'https://play.google.com/store/apps/details?id=com.humantelligence'
              : 'https://itunes.apple.com/co/app/humantelligence/id1423332040',
          $ios_url:
            'https://itunes.apple.com/co/app/humantelligence/id1423332040'
        }
      };

      const branchLink = await handleBranchLink(linkData);

      return branchLink;
    }

    return HUMAN_LINK.concat(`/${store_url}`);
  };

  const handleBranchLink = linkData =>
    new Promise(success => {
      Branch.link(linkData, (err, link) => {
        success(link || err);
      });
    });

  const goAppStore = async () => {
    const link = await handleStores('app_store');

    document.location.href = link;
  };

  const goPlayStore = async () => {
    const link = await handleStores('google_play');

    document.location.href = link;
  };

  return { goAppStore, goPlayStore };
};

export default useAppLinks;
