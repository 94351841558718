import React from 'react';

import CustomField from '../../../controls/CustomField';
import Button from '../../../controls/Button';
import { Form } from 'formik';

import { useTranslation } from 'react-i18next';
import injectSheet from 'react-jss';

function PasswordResetForm({ classes }) {
  const { t } = useTranslation();

  return (
    <Form className={classes.container}>
      <p className={classes.forgotPassword}>{t('userAuth.forgotPassword')}</p>
      <p className={classes.forgotPasswordText}>
        {t('userAuth.forgotPasswordText')}
      </p>

      <CustomField
        type="email"
        name="email"
        label={t('app.inputsEmail')}
        special="*"
      />

      <Button type="submit" color="blue">
        {t('app.submit')}
      </Button>
    </Form>
  );
}

const styles = {
  '@global': {
    '*': {
      fontFamily: "'Fira Sans' !important"
    }
  },

  container: {
    margin: { left: 20, right: 20, bottom: 20 }
  },

  forgotPassword: {
    fontSize: 23,
    alignSelf: 'stretch',
    marginTop: 20,
    marginBottom: 5,
    backgroundColor: 'transparent',
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Fira Sans',
    fontWeight: 600
  },

  forgotPasswordText: {
    fontSize: 18,
    marginRight: 40,
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Fira Sans',
    fontWeight: 300
  }
};

export default injectSheet(styles)(PasswordResetForm);
