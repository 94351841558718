import React, { useState, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Branch from 'branch-sdk';
import injectSheet from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';

import { routing } from '../../constants';
import getMobileOperatingSystem from '../../helpers/platformDetection';
import { getDeepLinkSpecial, postDeepLinkSpecial } from './dashboardAPI';

function Dashboard({ classes }) {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(10);
  const { currentUser, branchData } = useStoreState(store => ({
    currentUser: store.user.currentUser,
    branchData: store.branch.data
  }));

  const currentPlatform = useMemo(() => getMobileOperatingSystem(), []);
  const isDesktop =
    currentPlatform === 'windows' || currentPlatform === 'unknown';

  useEffect(() => {
    async function callAPI() {
      const { special, ...data } = branchData;

      if (!!special) {
        const deepLinkURI = data['~referring_link'].split('/');
        const deepLinkName = deepLinkURI[deepLinkURI.length - 1];

        const { status, data: json } = await getDeepLinkSpecial(deepLinkName);

        if (status !== 200) return null;

        await postDeepLinkSpecial(json.data.service, json.data.name, {
          email: currentUser.email,
          accountId: json.data.accountId,
          groupId: json.data.groupId
        });

        Branch.logEvent(`OPEN_SPECIAL:${data.type}`, {
          user_email: currentUser.email,
          user_id: currentUser.id
        });

        return null;
      }
    }

    callAPI();
  }, [currentUser, branchData]);

  useEffect(() => {
    let interval = null;

    if (currentPlatform === 'android' || currentPlatform === 'ios') {
      interval = setInterval(() => setSeconds(seconds - 1), 1000);
    }

    if (seconds === 0) {
      if (currentPlatform === 'ios') {
        window.location.href =
          'https://itunes.apple.com/us/app/humantelligence/id1423332040?l=es&ls=1&mt=8';
      } else if (currentPlatform === 'android')
        window.location.href =
          'http://play.google.com/store/apps/details?id=com.humantelligence&hl=en';

      !!interval && clearInterval(interval);
    }

    return () => !!interval && clearInterval(interval);
  }, [currentPlatform, seconds]);

  if (isDesktop) {
    return <Redirect to={routing.CONTENT_HOME_PATH} />;
  }

  return (
    <div className={classes.container}>
      <div>
        <h3>
          {t('app.greetings', {
            1: currentUser.firstName,
            2: currentUser.lastName
          })}
        </h3>

        <h4>{t('app.willRedirect', { 1: seconds })}</h4>
      </div>
    </div>
  );
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    paddingLeft: 20,
    paddingRight: 20,

    backgroundColor: theme.blue,

    textAlign: 'center',
    color: '#FFF',
    fontSize: 30
  }
});

export default injectSheet(styles)(Dashboard);
