import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { routing } from '../constants';

export default function PrivateRoute(props) {
  const { isLoggedIn } = useStoreState(store => store.user.currentUser);

  return isLoggedIn ? (
    <Route {...props} />
  ) : (
    <Redirect to={`${routing.AUTH_PATH}/login`} />
  );
}
