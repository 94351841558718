import React, { useState } from 'react';

import CustomField from '../../../controls/CustomField';
import Button from '../../../controls/Button';
import { Form } from 'formik';

import { useTranslation } from 'react-i18next';
import injectSheet from 'react-jss';
import { PasswordAccessory } from '../../../controls/Accessory';

function PasswordConfirmationForm({ classes, values, setFieldValue }) {
  const { t } = useTranslation();
  const [isPasswordHidden, setPasswordVisibility] = useState(true);
  const [isConfirmationHidden, setConfirmationVisibility] = useState(true);

  const togglePasswordVisibility = () =>
    setPasswordVisibility(!isPasswordHidden);
  const toggleConfirmationVisibility = () =>
    setConfirmationVisibility(!isConfirmationHidden);

  return (
    <Form className={classes.container}>
      <p className={classes.forgotPasswordConfirmationHeader}>
        {t('userAuth.forgotPasswordConfirmationHeader')}
      </p>
      <p className={classes.forgotPasswordConfirmationText}>
        {t('userAuth.forgotPasswordConfirmationText')}
      </p>

      <CustomField
        type={isPasswordHidden ? 'password' : 'default'}
        name="password"
        label={t('userAuth.inputsForgotPassword')}
        special="*"
        Accessory={
          <PasswordAccessory
            onClick={togglePasswordVisibility}
            isSecondary={isPasswordHidden}
          />
        }
      />

      <CustomField
        type={isConfirmationHidden ? 'password' : 'default'}
        name="passwordConfirmation"
        label={t('userAuth.inputsForgotPasswordConfirmation')}
        special="*"
        Accessory={
          <PasswordAccessory
            onClick={toggleConfirmationVisibility}
            isSecondary={isConfirmationHidden}
          />
        }
      />

      <Button type="submit" color="blue">
        {t('userAuth.forgotPasswordSubmit')}
      </Button>
    </Form>
  );
}

const styles = {
  '@global': {
    '*': {
      fontFamily: "'Fira Sans' !important"
    }
  },

  container: {
    margin: { left: 20, right: 20, bottom: 20 }
  },

  forgotPasswordConfirmationHeader: {
    fontSize: 23,
    alignSelf: 'stretch',
    marginTop: 20,
    marginBottom: 5,
    backgroundColor: 'transparent',
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Fira Sans',
    fontWeight: 600
  },
  forgotPasswordConfirmationText: {
    fontSize: 18,
    marginRight: 40,
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Fira Sans',
    fontWeight: 300
  }
};

export default injectSheet(styles)(PasswordConfirmationForm);
