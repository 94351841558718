import React from 'react';
import classNames from 'classnames';

import descriptionWrapperStyles from './styles';

export default function DescriptionWrapper({ title, description, children }) {
  const styles = descriptionWrapperStyles();
  const titleClasses = classNames('text-center mt-2', styles.title);
  const descriptionClasses = classNames('text-left', styles.description);

  return (
    <div className="bg-group">
      <div className="col-md-8 pt-3 pb-5 mx-auto">
        {title ? <h2 className={titleClasses}>{title}</h2> : null}
        {description ? (
          <h4 className={descriptionClasses}>{description}</h4>
        ) : null}
        {children}
      </div>
    </div>
  );
}
