import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import injectSheet from 'react-jss';

import { routing } from '../../../constants';
import Preloader from '../../../controls/Preloader';
import DescriptionWrapper from '../../../controls/DescriptionWrapper';
import Thumbnail from '../../../controls/Thumbnail';
import Ellipsis from '../../../controls/Ellipsis';
import Rate from '../../../controls/Rate';

import API from '../../../api/baseAPI';

function Resources({ classes }) {
  const { id } = useParams();
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const loadUserData = async () => {
      const { status, data } = await API.get(
        `career_coach/resource-categories/${id}/resources`
      );

      const { data: json } = data;

      if (status === 200 && json) {
        const resourcesData = json.resources;
        setResources(resourcesData || []);
      }
    };

    async function fetchData() {
      setLoading(true);
      await loadUserData();
      setLoading(false);
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mainTitle = state && state.title ? state.title : 'My Guru';

  return isLoading ? (
    <Preloader />
  ) : (
    <DescriptionWrapper title={mainTitle}>
      <div className="list-group mt-3">
        {resources.map(
          ({
            id,
            url,
            title,
            thumbnailUrl,
            description,
            averageScore,
            reviewsCount
          }) => (
            <Link
              key={id}
              className="list-group-item list-group-item-action d-flex align-items-center rounded mb-3 pt-3 pb-4"
              to={`${routing.CONTENT_PATH}/visor/${id}?url=${url}&title=${title}`}>
              <Thumbnail src={thumbnailUrl} />
              <span className="mx-3 align-self-stretch">
                <h4>{title}</h4>
                <Ellipsis text={description} />
              </span>
              <span className="ml-auto px-1">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-chevron-compact-right "
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                  />
                </svg>
              </span>
              <span className={classes.ratingContainer}>
                <Rate initialRating={averageScore} readonly />
                <span className="count px-1">{`(${reviewsCount})`}</span>
              </span>
            </Link>
          )
        )}
      </div>
    </DescriptionWrapper>
  );
}

const styles = {
  ratingContainer: {
    bottom: 5,
    display: 'flex',
    position: 'absolute',
    right: 5,
    '& .count': {
      letterSpacing: '2px',
      marginTop: '2px'
    }
  }
};

export default injectSheet(styles)(Resources);
