import { createUseStyles } from 'react-jss';

const descriptionWrapperStyles = createUseStyles({
  '@global': {
    body: {
      fontFamily: 'ProximaNovaRegular',
      lineHeight: '25px'
    },
    '.bg-group': {
      background: '#496bf9'
    }
  },
  title: {
    color: 'white',
    fontSize: '36px',
    fontWeight: '600',
    lineHeight: '44px',
    marginTop: '50px'
  },
  description: {
    color: 'white',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '26px'
  }
});

export default descriptionWrapperStyles;
