import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { StoreProvider } from 'easy-peasy';
import { ScreenClassProvider } from 'react-grid-system';

import Preloader from './controls/Preloader';
import AppThemeProvider from './context/AppThemeProvider';

import App from './App';
import AppRouter from './AppRouter';
import { ToastProvider } from 'react-toast-notifications';

import i18n from './i18n';
import { store } from './model/model';
import history from './history';

export default function Base() {
  return (
    <ScreenClassProvider>
      <AppThemeProvider>
        <Suspense fallback={<Preloader />}>
          <StoreProvider store={store}>
            <I18nextProvider i18n={i18n}>
              <Router history={history}>
                <ToastProvider placement="top-center">
                  <App>
                    <AppRouter />
                  </App>
                </ToastProvider>
              </Router>
            </I18nextProvider>
          </StoreProvider>
        </Suspense>
      </AppThemeProvider>
    </ScreenClassProvider>
  );
}
