import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles';

export default function Card({
  link,
  children,
  type = 'default',
  tagMessage = null
}) {
  const classes = styles();
  const containerClasses = classNames(classes.container, {
    [classes[type]]: !!type
  });
  const linkClasses = classNames(classes.link, {
    [classes[`${type}Text`]]: !!type
  });

  return (
    <div className={containerClasses}>
      <Link className={linkClasses} to={link}>
        {children}
        {tagMessage && <div className={classes.cornerRibbon}>{tagMessage}</div>}
      </Link>
    </div>
  );
}

Card.propTypes = {
  type: PropTypes.oneOf(['default', 'featured']),
  link: PropTypes.string,
  tagMessage: PropTypes.string,
  children: PropTypes.node
};
