import React, { useState, useEffect } from 'react';
import humps from 'humps';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DescriptionWrapper from '../../../controls/DescriptionWrapper';
import Preloader from '../../../controls/Preloader';
import ComingSoon from '../../../controls/ComingSoon/ComingSoon';

import { routing } from '../../../constants';
import API from '../../../api/baseAPI';

import resourceCategoriesStyles from './styles';

export default function ResourceCategories() {
  resourceCategoriesStyles();
  const { t } = useTranslation(['remote']);
  const { resourceType } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [resourceCategories, setResourcesCategories] = useState([]);
  const [comingSoon, setComingSoon] = useState(false);

  useEffect(() => {
    const loadUserData = async () => {
      const { status, data: json } = await API.get(
        `career_coach/resource-categories?type=${humps.decamelize(
          resourceType
        )}`
      );

      if (status === 200 && json) {
        const resourceCategories = json.data.resourceCategories;
        if (resourceCategories.length <= 0 || resourceCategories === null)
          setComingSoon(true);
        else setComingSoon(false);

        setResourcesCategories(resourceCategories || []);
      }
    };

    async function fetchData() {
      setLoading(true);
      await loadUserData();
      setLoading(false);
    }

    fetchData();
  }, [resourceType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = t(`remote:auth.${resourceType}Label`);
  const description = t(`remote:auth.${resourceType}Description`);

  const renderCategory = ({ id, name }) => (
    <Link
      key={id}
      className="list-group-item list-group-item-action d-flex justify-content-between align-items-center rounded mb-3"
      to={{
        pathname: `${routing.CONTENT_PATH}/resource/${id}`,
        state: { title: name }
      }}>
      {name}
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-chevron-compact-right"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
        />
      </svg>
    </Link>
  );

  return isLoading ? (
    <Preloader />
  ) : (
    <DescriptionWrapper title={title} description={description}>
      {comingSoon ? (
        <div className="d-flex flex-column align-items-center mt-3">
          <ComingSoon />
        </div>
      ) : (
        <div className="list-group mt-3">
          {resourceCategories.map(renderCategory)}
        </div>
      )}
    </DescriptionWrapper>
  );
}
