import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Home from '../features/Home/Home';
import MyProfile from '../features/Home/MyProfile';
import Teams from '../features/Home/Teams';
import CareerCoach from '../features/Home/CareerCoach';

import Menu from '../controls/Menu';

export default function ContentAppRouter() {
  return (
    <>
      <Menu />
      <Switch>
        <Route strict exact path="/" component={Home} />
        <Route exact path="/my-profile" component={MyProfile} />
        <Route exact path="/teams" component={Teams} />
        <Route exact path="/career-coach" component={CareerCoach} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}
