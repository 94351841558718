import { useState, useEffect } from 'react';

import API from '../api/baseAPI';

const useCountries = () => {
  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    const {
      data: { data }
    } = await API.get('countries');

    setCountries(data.map(({ alpha2, name }) => ({ id: alpha2, label: name })));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return countries;
};

export default useCountries;
