import { useEffect, useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import keyMirror from 'keymirror';
import isNil from 'lodash/isNil';

const requiresAssessment = true;
const app = (
  id,
  assessment = false,
  category,
  comingSoon = false,
  isFeaturedApp
) => ({
  id,
  requiresAssessment: assessment,
  category,
  empty: isNil(id),
  comingSoon,
  isFeaturedApp
});

const CATEGORIES = keyMirror({
  selfAwareness: true,
  educationResources: true,
  remoteWork: true,
  careerAndJobs: true,
  funStuff: true
});

const APPS = [
  app('pf', requiresAssessment, CATEGORIES.selfAwareness, false, true),
  app('tc', requiresAssessment, CATEGORIES.selfAwareness, false, true),
  app('fd', requiresAssessment, CATEGORIES.selfAwareness, false, true),
  // Coming soon disabled
  // app('health', !requiresAssessment, CATEGORIES.selfAwareness, true),
  // app('spiritual', !requiresAssessment, CATEGORIES.selfAwareness, true),

  // College
  app('applyCollege', !requiresAssessment, CATEGORIES.educationResources),
  app('college', !requiresAssessment, CATEGORIES.educationResources),
  // Coming soon disabled
  // app(
  //   'graduateSchool',
  //   !requiresAssessment,
  //   CATEGORIES.educationResources,
  //   true
  // ),
  // app(
  //   'onlinePrograms',
  //   !requiresAssessment,
  //   CATEGORIES.educationResources,
  //   true
  // ),
  // app('learning', !requiresAssessment, CATEGORIES.educationResources, true),

  // Remote Work
  app('rws', requiresAssessment, CATEGORIES.remoteWork, false, true),
  app('remote', requiresAssessment, CATEGORIES.remoteWork),

  // Career And Jobs
  app('cc', requiresAssessment, CATEGORIES.careerAndJobs, false, true),
  app('findJob', !requiresAssessment, CATEGORIES.careerAndJobs),
  app('resumes', !requiresAssessment, CATEGORIES.careerAndJobs),
  app('salaryInfo', !requiresAssessment, CATEGORIES.careerAndJobs),
  app('interviewing', !requiresAssessment, CATEGORIES.careerAndJobs),
  // Coming soon disabled
  // app('networking', !requiresAssessment, CATEGORIES.careerAndJobs, true),
  // app('myJob', !requiresAssessment, CATEGORIES.careerAndJobs, true),
  // app('tests', !requiresAssessment, CATEGORIES.careerAndJobs, true),
  // app('entrepreneurship', !requiresAssessment, CATEGORIES.careerAndJobs, true),
  // app('jobs', !requiresAssessment, CATEGORIES.careerAndJobs, true),
  // app('coaches', !requiresAssessment, CATEGORIES.careerAndJobs, true),
  // app('directories', !requiresAssessment, CATEGORIES.careerAndJobs, true),
  // app('recruiters', !requiresAssessment, CATEGORIES.careerAndJobs, true),

  // Fun Stuff
  app('highSchool', !requiresAssessment, CATEGORIES.funStuff),
  app('fun', !requiresAssessment, CATEGORIES.funStuff)
];

const useApps = () => {
  const modelApps = useStoreState(store => store.apps.list);
  const updateApps = useCallback(
    useStoreActions(store => store.apps.update),
    []
  );

  useEffect(() => {
    updateApps({ ...APPS });
  }, [updateApps]);

  return modelApps;
};

export const getAppI18n = id => {
  switch (id) {
    case 'fd':
      return 'friends';
    case 'cc':
      return 'careerCoach';
    case 'tc':
      return 'teamCulture';
    case 'pf':
      return 'profile';
    case 'rws':
      return 'remoteScorecard';
    default:
      return id;
  }
};

export default useApps;
