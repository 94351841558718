import React from 'react';

import injectSheet from 'react-jss';

import video from '../../../assets/videos/cheerUp.mp4';

function BackgroundVideo({ classes, children }) {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <video
          className={classes.video}
          loop
          autoPlay
          muted
          preload="auto"
          playsInline>
          <source src={video} type="video/mp4" />
        </video>
      </div>

      <div className={classes.children}>{children}</div>
    </div>
  );
}

const styles = theme => ({
  container: {
    display: 'flex',
    height: '100vh',
    position: 'relative'
  },
  innerContainer: {
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden'
  },
  video: { width: '100%', height: '100%', objectFit: 'cover' },
  children: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    width: '100vw',
    backgroundColor: theme.videoOpacity
  }
});

export default injectSheet(styles)(BackgroundVideo);
