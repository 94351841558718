import { createStore } from 'easy-peasy';

import branchModel from './branchModel';
import userModel from './userModel';
import appsModel from './appsModel';

const model = {
  branch: branchModel,
  user: userModel,
  apps: appsModel
};

export const store = createStore(model, { storage: 'localStorage' });

export default model;
