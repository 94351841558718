import ScrollReveal from 'scrollreveal';

export default function startScrollReveal() {
  var slideUp = {
    distance: '30%',
    origin: 'bottom',
    duration: 1500,
    delay: 300,
    opacity: 0
  };

  ScrollReveal().reveal('.app-phone', slideUp);
  ScrollReveal().reveal('.reveal', { delay: 300 });
}
