import { createUseStyles } from 'react-jss';

import Pattern from '../../../assets/images/pattern.jpg';

const sectionsStyles = createUseStyles({
  '@global': {
    body: {
      fontFamily: 'ProximaNovaRegular',
      fontSize: '18px',
      lineHeight: '25px'
    },
    '.bg-group': {
      background: '#496bf9'
    },
    '.bg-friends': {
      background: '#1dafd1'
    },
    '.bg-career': {
      background: '#21b791'
    },
    '.bg-match': {
      background: '#7243f0'
    },
    '.bg-dating': {
      background: '#fa4e5a'
    },
    '.bg-couples': {
      background: '#f3796f'
    },
    '.bg-purple': {
      background: '#7243f0'
    },
    '.bg-remotework': {
      background: '#6f9af3'
    },
    '.purple': {
      color: '#7243f0'
    },
    '.yellow': {
      color: '#ffee00'
    },
    '.bg-white': {
      background: '#fff',
      color: '#333 !important'
    },
    '.bg-gray': {
      background: '#f3f6ff',
      color: '#333 !important'
    },
    'a:hover': {
      textDecoration: 'none'
    },
    h1: {
      fontSize: '36px',
      lineHeight: '48px',
      fontWeight: 600
    },
    h2: {
      fontSize: '48px',
      lineHeight: '44px',
      fontWeight: '600'
    },
    h4: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '500'
    },
    '.screens': {
      padding: '2.5rem 0'
    },
    '.screens.bubbles': {
      backgroundColor: '#496bf9'
    },
    '.screens.white': {
      background: 'white'
    },
    '.screens.bubbles h2.title, .screens.bubbles h4.description, .screens.bubbles li, .screens.bubbles p': {
      color: 'white'
    },
    '.screens h2.title': {
      marginTop: '50px',
      fontSize: '36px'
    },
    '.screens h4': {
      fontSize: '20px'
    },
    '.screens.fun': {
      backgroundColor: '#7243f0',
      backgroundImage: `url(${Pattern})`,
      color: 'white'
    }
  }
});

export default sectionsStyles;
