import React, { Fragment } from 'react';

import HTApp from '../../assets/images/ht-app-phone4-2x.png';
import HTFooter from '../../assets/images/ht-logo-footer.png';

import AppStore from '../../assets/images/appstore.png';
import GooglePlay from '../../assets/images/googleplay.png';
import Facebook from '../../assets/images/facebook-icon.png';
import Instagram from '../../assets/images/instagram-icon.png';
import Twitter from '../../assets/images/twitter-icon.png';
import appStoreIcon from '../../assets/images/app-store-icon.png';
import playStoreIcon from '../../assets/images/play-store-icon.png';
import starIcon from '../../assets/images/star.png';

// icons
import IconAI from '../../assets/images/icons/artificial-intelligence.png';
import IconBank from '../../assets/images/icons/bank.png';
import IconCampus from '../../assets/images/icons/campus.png';
import IconCV from '../../assets/images/icons/curriculum-vitae.png';
import IconGoogle from '../../assets/images/icons/google.png';
import IconJobSearch from '../../assets/images/icons/job-search.png';
import IconMeeting1 from '../../assets/images/icons/meeting-1.png';
import IconMeeting from '../../assets/images/icons/meeting.png';
import IconNetwork from '../../assets/images/icons/network.png';
import IconEducation from '../../assets/images/icons/online-education.png';
import IconPortfolio from '../../assets/images/icons/portfolio.png';
import IconResume from '../../assets/images/icons/resume.png';
import IconRubik from '../../assets/images/icons/rubik.png';
import IconTeam from '../../assets/images/icons/team.png';
import IconBrain from '../../assets/images/icons/brain.png';
import IconBoardGames from '../../assets/images/icons/board-games.png';
import IconMobile from '../../assets/images/icons/mobile.png';
import IconBubble from '../../controls/IconBubble';

import { useTranslation } from 'react-i18next';
import useAppLinks from '../../hooks/useAppLinks';
import useHome from './useHome';

function Home() {
  useHome();

  const { t } = useTranslation();
  const { goAppStore, goPlayStore } = useAppLinks();

  return (
    <Fragment>
      <header className="header pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-white">
              <h1 className="mt-4 pt-0 mt-md-4 pt-md-4 mb-5 mb-md-4 yellow">
                {t('home.main.title')}
              </h1>
              <h4
                className="mb-5 mb-md-4"
                dangerouslySetInnerHTML={{ __html: t('home.main.textLine1') }}
              />
              <p>{t('home.main.textLine2')}</p>
              <p>{t('home.main.textLine3')}</p>
              <div className="app-buttons">
                <div className="row">
                  <div className="col-sm-12 text-center-xs">
                    <img
                      src={AppStore}
                      className="img-fluid app-button inline-block mr-2 my-2"
                      alt="Appstore"
                      onClick={goAppStore}
                    />
                    <img
                      src={GooglePlay}
                      className="img-fluid app-button inline-block"
                      alt="Googleplay"
                      onClick={goPlayStore}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <h3 className="text-white mb-3 mt-4">
                {t('home.main.trademark')}
              </h3>
              <img
                src={HTApp}
                className="img-fluid app-phone load-hidden"
                alt="Ht app phone3 2x"
              />
            </div>
          </div>
        </div>
      </header>
      <section className="screens white shadow-inner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="title mt-1 mb-4">{t('home.video.title')}</h2>
              <iframe
                className="rounded"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NPcFgrDJOlQ?controls=0"
                frameborder="0"
                allow="picture-in-picture"
                allowfullscreen
                title="Video"></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: t('home.features.title') }}
              />
              <hr className="small-hr mt-1 mb-4" />
              <p
                dangerouslySetInnerHTML={{ __html: t('home.features.text1') }}
              />
              <p>{t('home.features.text2')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-0 pt-5 mb-4">
              <div className="card-deck pb-3">
                <a
                  href="#selfAwareness"
                  className="card bg-group text-white reveal load-hidden">
                  <img
                    src={IconGoogle}
                    width="60px"
                    className="inline-block img-fluid mt-4 ml-4"
                    alt="Friends"
                  />
                  <div className="card-body mt-3">
                    <h5 className="card-title">
                      {t('home.features.selfAwareness')}
                    </h5>
                    <p className="card-text text-small">
                      {t('home.features.selfAwarenessText')}
                    </p>
                  </div>
                </a>
                <a
                  href="#educationResources"
                  className="card bg-friends text-white reveal load-hidden">
                  <img
                    src={IconEducation}
                    width="60px"
                    className="inline-block img-fluid mt-4 ml-4"
                    alt="Career"
                  />
                  <div className="card-body mt-3">
                    <h5 className="card-title">
                      {t('home.features.educationResources')}
                    </h5>
                    <p className="card-text text-small">
                      {t('home.features.educationResourcesText')}
                    </p>
                  </div>
                </a>
                <a
                  href="#remoteWork"
                  className="card bg-remotework text-white reveal load-hidden">
                  <img
                    src={IconMobile}
                    width="60px"
                    className="inline-block img-fluid mt-4 ml-4"
                    alt="Career"
                  />
                  <div className="card-body mt-3">
                    <h5 className="card-title">
                      {t('home.features.remoteWork')}
                    </h5>
                    <p className="card-text text-small">
                      {t('home.features.remoteWorkText')}
                    </p>
                  </div>
                </a>
                <a
                  href="#careerAndJobs"
                  className="card bg-career text-white mt-120 reveal load-hidden">
                  <img
                    src={IconJobSearch}
                    width="60px"
                    className="inline-block img-fluid mt-4 ml-4"
                    alt="Group"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {t('home.features.careerAndJobs')}
                    </h5>
                    <p className="card-text text-small">
                      {t('home.features.careerAndJobsText')}
                    </p>
                  </div>
                </a>
                <a
                  href="#funStuff"
                  className="card bg-purple text-white mt-120 reveal load-hidden hand">
                  <img
                    src={IconRubik}
                    width="60px"
                    className="inline-block img-fluid mt-4 ml-4"
                    alt="Group"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {t('home.features.funStuff')}
                    </h5>
                    <p className="card-text text-small">
                      {t('home.features.funStuffText')}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* new content */}

      <section id="selfAwareness" className="screens arrow-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-10">
                {t('home.features.introduction')}
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="selfAwareness" className="screens bubbles pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-5">
                {t('home.selfAwareness.title')}
              </h2>
              <h4 className="text-left">
                {t('home.selfAwareness.description')}
              </h4>
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-md-6">
              <IconBubble icon={IconGoogle} color="green" link="/my-profile">
                <h3>{t('home.selfAwareness.myPersonality.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.selfAwareness.myPersonality.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconTeam} color="green" link="/teams">
                <h3>{t('home.selfAwareness.teamCulture.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.selfAwareness.teamCulture.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconNetwork} color="green" showViewMore={false}>
                <h3>{t('home.selfAwareness.myNetwork.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.selfAwareness.myNetwork.description')
                  }}></p>
              </IconBubble>
            </div>
          </div>
        </div>
      </section>

      {/* section white */}

      <section id="educationResources" className="screens white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-10">
                {t('home.education.title')}
              </h2>
              <h4 className="text-left">{t('home.education.description')}</h4>
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-md-6">
              <IconBubble icon={IconCV}>
                <h3>{t('home.education.applyingToCollege.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.education.applyingToCollege.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconCampus}>
                <h3>{t('home.education.atCollege.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.education.atCollege.description')
                  }}></p>
              </IconBubble>
            </div>
          </div>
        </div>
      </section>

      {/* remote work */}
      <section id="remoteWork" className="screens bubbles">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-10">
                {t('home.remoteWork.title')}
              </h2>
              <h4 className="text-left">{t('home.remoteWork.description')}</h4>
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-md-6">
              <IconBubble
                icon={IconMeeting1}
                color="green"
                showViewMore={false}>
                <h3>{t('home.remoteWork.remoteWork.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.remoteWork.remoteWork.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconMobile} showViewMore={false}>
                <h3>{t('home.remoteWork.RWResources.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.remoteWork.RWResources.description')
                  }}></p>
              </IconBubble>
            </div>
          </div>
        </div>
      </section>

      {/* section gray */}

      <section id="careerAndJobs" className="screens white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-10">
                {t('home.careerAndJobs.title')}
              </h2>
              <h4 className="text-left">
                {t('home.careerAndJobs.description')}
              </h4>
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-md-6">
              <IconBubble
                icon={IconPortfolio}
                color="green"
                link="/career-coach">
                <h3>{t('home.careerAndJobs.careerMatch.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.careerAndJobs.careerMatch.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconJobSearch}>
                <h3>{t('home.careerAndJobs.findJob.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.careerAndJobs.findJob.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconResume}>
                <h3>{t('home.careerAndJobs.resumeAndApplications.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'home.careerAndJobs.resumeAndApplications.description'
                    )
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconBank}>
                <h3>{t('home.careerAndJobs.salaryInfo.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.careerAndJobs.salaryInfo.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconMeeting}>
                <h3>{t('home.careerAndJobs.interviewing.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.careerAndJobs.interviewing.description')
                  }}></p>
              </IconBubble>
            </div>
          </div>
        </div>
      </section>

      {/* fun */}
      <section id="funStuff" className="screens fun">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-10">
                {t('home.funStuff.title')}
              </h2>
              <h4 className="text-left">{t('home.funStuff.description')}</h4>
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-md-6">
              <IconBubble icon={IconRubik} showViewMore={false}>
                <h3>{t('home.funStuff.highSchool.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.funStuff.highSchool.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconBrain} showViewMore={false}>
                <h3>{t('home.funStuff.college.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.funStuff.college.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconAI}>
                <h3>{t('home.funStuff.brainApps.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.funStuff.brainApps.description')
                  }}></p>
              </IconBubble>
            </div>
            <div className="col-md-6">
              <IconBubble icon={IconBoardGames}>
                <h3>{t('home.funStuff.virtualGames.title')}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('home.funStuff.virtualGames.description')
                  }}></p>
              </IconBubble>
            </div>
          </div>
        </div>
      </section>

      {/* end new conent */}

      <section className="screens bubbles">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-1 mb-5">
                {t('home.reviews.title')}
              </h2>
            </div>
          </div>
          <div className="row row-eq-height">
            <div className="col-md-6">
              <div className="review-box">
                <a
                  href="https://apps.apple.com/us/app/humantelligence/id1423332040#see-all/reviews"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className="title">
                    <img src={appStoreIcon} alt="" />
                    <h4>{t('home.reviews.appStore.name')}</h4>
                  </div>
                </a>
                <div className="reviews">
                  <div className="review">
                    <h5>{t('home.reviews.appStore.title1')}</h5>
                    <p>{t('home.reviews.appStore.text1')}</p>
                    <div className="stars">
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                    </div>
                  </div>
                  <div className="review">
                    <h5>{t('home.reviews.appStore.title2')}</h5>
                    <p>{t('home.reviews.appStore.text2')}</p>
                    <div className="stars">
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="review-box">
                <a
                  href="https://play.google.com/store/apps/details?id=com.humantelligence"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className="title">
                    <img src={playStoreIcon} alt="" />
                    <h4>{t('home.reviews.playStore.name')}</h4>
                  </div>
                </a>
                <div className="reviews">
                  <div className="review">
                    <h5>{t('home.reviews.playStore.title1')}</h5>
                    <p>{t('home.reviews.playStore.text1')}</p>
                    <div className="stars">
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                    </div>
                  </div>
                  <div className="review">
                    <h5>{t('home.reviews.playStore.title2')}</h5>
                    <p>{t('home.reviews.playStore.text2')}</p>
                    <div className="stars">
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                      <img src={starIcon} alt="" className="star" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="download text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <p className="text-big">{t('home.download.text2')}</p>
              <p>{t('home.download.text3')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4 text-center">
              <img
                src={AppStore}
                className="img-fluid app-button inline-block mr-2 my-2"
                alt="Appstore"
                onClick={goAppStore}
              />

              <img
                src={GooglePlay}
                className="img-fluid app-button inline-block"
                alt="Googleplay"
                onClick={goPlayStore}
              />
            </div>
          </div>
        </div>
      </section>
      <footer className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <a
                href="https://humantelligence.com"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={HTFooter}
                  width="194px"
                  className="img-fluid inline-block"
                  alt="Ht logo footer"
                />
              </a>
              <p className="text-small mt-3">{t('home.main.rights')}</p>
            </div>
            <div className="col-6 text-right">
              <a
                href="https://www.facebook.com/Humantelligence/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Facebook}
                  width="12px"
                  className="img-fluid inline-block mr-3"
                  alt="Facebook icon"
                />
              </a>
              <a
                href="https://www.instagram.com/humantelligence/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Instagram}
                  width="25px"
                  className="img-fluid inline-block mr-3"
                  alt="Instagram icon"
                />
              </a>
              <a
                href="https://twitter.com/humantelligence"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Twitter}
                  width="25px"
                  className="img-fluid inline-block"
                  alt="Twitter icon"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default Home;
