import { createUseStyles } from 'react-jss';

const resourceCategoriesStyles = createUseStyles({
  '@global': {
    body: {
      fontFamily: 'ProximaNovaRegular',
      fontSize: '18px',
      lineHeight: '25px'
    }
  }
});

export default resourceCategoriesStyles;
