// icons
import IconBank from '../assets/images/icons/bank.png';
import IconBookshelf from '../assets/images/icons/bookshelf.png';
import IconCampus from '../assets/images/icons/campus.png';
import IconCV from '../assets/images/icons/curriculum-vitae.png';
import IconDetective from '../assets/images/icons/detective.png';
import IconEntrepreneur from '../assets/images/icons/entrepreneur.png';
import IconExam from '../assets/images/icons/exam.png';
import IconFiles from '../assets/images/icons/files.png';
import IconHandShake from '../assets/images/icons/handshake.png';
import IconHeartBeat from '../assets/images/icons/heartbeat1.png';
import IconJobSearch from '../assets/images/icons/job-search.png';
import IconLotus from '../assets/images/icons/lotus.png';
import IconMeeting from '../assets/images/icons/meeting.png';
import IconEducation from '../assets/images/icons/online-education.png';
import IconPhd from '../assets/images/icons/phd.png';
import IconResume from '../assets/images/icons/resume.png';
import IconRubik from '../assets/images/icons/rubik.png';
import IconTime from '../assets/images/icons/time-management.png';
import IconTrainer from '../assets/images/icons/trainer.png';
import IconBrain from '../assets/images/icons/brain.png';
import IconMobile from '../assets/images/icons/mobile.png';
import IconGoogle from '../assets/images/icons/google.png';
import IconTeam from '../assets/images/icons/team.png';
import IconNetwork from '../assets/images/icons/network.png';
import IconRemote from '../assets/images/icons/meeting-1.png';
import IconPortfolio from '../assets/images/icons/portfolio.png';

export function getCategoryIcon(id) {
  switch (id) {
    case 'pf':
      return IconGoogle;
    case 'tc':
      return IconTeam;
    case 'fd':
      return IconNetwork;
    case 'rws':
      return IconRemote;
    case 'cc':
      return IconPortfolio;
    case 'spiritual':
      return IconLotus;
    case 'health':
      return IconHeartBeat;
    case 'applyCollege':
      return IconCV;
    case 'college':
      return IconCampus;
    case 'graduateSchool':
      return IconPhd;
    case 'onlinePrograms':
      return IconEducation;
    case 'remote':
      return IconMobile;
    case 'myJob':
      return IconJobSearch;
    case 'salaryInfo':
      return IconBank;
    case 'coaches':
      return IconTrainer;
    case 'tests':
      return IconExam;
    case 'entrepreneurship':
      return IconEntrepreneur;
    case 'jobs':
      return IconTime;
    case 'directories':
      return IconFiles;
    case 'findJob':
      return IconJobSearch;
    case 'recruiters':
      return IconDetective;
    case 'learning':
      return IconBookshelf;
    case 'networking':
      return IconHandShake;
    case 'interviewing':
      return IconMeeting;
    case 'resumes':
      return IconResume;
    case 'highSchool':
      return IconRubik;
    case 'fun':
      return IconBrain;
    default:
      return IconMeeting;
  }
}
