import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';

import { routing } from '../constants';

import ArrowLeftIcon from './Icon/ArrowLeftIcon';
import HTLogo from '../assets/images/logo-ht-app.png';

function TopBar({ classes, onBack, title, description }) {
  const { isLoggedIn } = useStoreState(store => store.user.currentUser);

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <button className={classes.button} onClick={onBack}>
          <ArrowLeftIcon size={25} color="#FFF" />
        </button>

        <div className={classes.title}>{title}</div>

        <Link
          to={isLoggedIn ? routing.CONTENT_HOME_PATH : '/'}
          className={classes.logo}>
          <img src={HTLogo} width="142" height="38" alt="Logo ht app" />
        </Link>
      </div>

      {!!description && (
        <div className={classes.description}>{description}</div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 15px',
    backgroundColor: 'rgb(75, 107, 249)'
  },

  inner: {
    display: 'flex',
    flex: '1 1 0%',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    backgroundColor: 'transparent'
  },

  button: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    height: 'auto',
    padding: '8px 8px 8px 10px',
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10
  },

  title: {
    fontSize: 22,
    marginLeft: 5,
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Fira Sans',
    fontWeight: 500
  },

  description: {
    fontSize: 18,
    marginTop: 10,
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Fira Sans',
    fontWeight: 300
  },
  logo: {
    marginLeft: 'auto'
  }
};

export default injectSheet(styles)(TopBar);
