import React, { useState } from 'react';

import ellipsisStyles from './styles';

export default function Ellipsis({
  text,
  length = 128,
  tail = '... ',
  viewMoreText = 'view more'
}) {
  const styles = ellipsisStyles();
  const [ellipsis, setEllipsis] = useState(true);

  const handleTextClick = e => {
    if (e.stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    }

    e.cancelBubble = true;
    e.returnValue = false;

    setEllipsis(!ellipsis);
    return false;
  };

  if (text.length <= length || length < 0) {
    return <span>{text}</span>;
  } else {
    const displayText =
      length - tail.length <= 0 ? '' : text.slice(0, length - tail.length);

    return ellipsis ? (
      <span onClick={handleTextClick}>
        {displayText}
        <span>
          {tail}
          <span className={styles.tailViewMore}>{viewMoreText}</span>
        </span>
      </span>
    ) : (
      <p onClick={handleTextClick}>{text}</p>
    );
  }
}
