import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import injectSheet from 'react-jss';

function Modal({ classes, children, onClose, isVisible, contentStyle = {} }) {
  const modalContainer = useRef(null);
  const content = useRef(null);

  const handleModalBackgroundClick = ({ target }) => {
    if ([modalContainer.current, content.current].includes(target)) onClose();
  };

  return ReactDOM.createPortal(
    <div
      ref={modalContainer}
      className={classes.modal}
      onClick={handleModalBackgroundClick}
      style={{ display: isVisible ? 'block' : 'none' }}>
      <div className="container" ref={content}>
        {children}
      </div>
    </div>,
    document.getElementById('modal')
  );
}

const styles = {
  modal: {
    display: 'block',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)'
  }
};

export default injectSheet(styles)(Modal);
