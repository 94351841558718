import API from '../../api/baseAPI';

export async function getDeepLinkSpecial(deepLinkName) {
  const response = await API.get(`deep_links/${deepLinkName}`);

  return response;
}

export async function postDeepLinkSpecial(service, name, data) {
  const response = await API.patch(`deep_links/${service}/${name}`, data);

  return response;
}
