import { useLoginUser, useRegisterUser } from './userHooks';

const useFacebook = () => {
  const {
    status: statusLogin,
    isLoading: isLoadingLogin,
    data: dataLogin,
    fireRequest: loginUser,
    error: errorLogin
  } = useLoginUser();
  const {
    status: statusRegister,
    isLoading: isLoadingRegister,
    data: dataRegister,
    fireRequest: registerUser,
    error: errorRegister
  } = useRegisterUser();

  const handleFBRegistration = async ({
    id,
    email,
    first_name,
    last_name,
    picture
  }) => {
    const user = {
      email,
      first_name,
      last_name,
      facebook_id: id,
      fb_app_id: id,
      api_social_token: id
    };

    if (picture)
      user.avatar = {
        url: picture.data.url,
        name: 'avatar.jpg',
        type: 'multipart/form-data'
      };

    registerUser(user);
  };

  const loginFb = async user => {
    const response = await loginUser({
      email: user.email,
      apiSocialToken: user.id,
      facebookId: user.id
    });

    if (response && response.status === 403) {
      handleFBRegistration(user);
    }
  };

  const error =
    errorLogin && errorLogin.status !== 403 ? errorLogin : errorRegister;

  return {
    loginFb,
    status: statusLogin || statusRegister,
    data: dataLogin || dataRegister,
    isLoading: isLoadingLogin || isLoadingRegister,
    error
  };
};

export default useFacebook;
