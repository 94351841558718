import React from 'react';
import Rating from 'react-rating';

const RateIcon = ({ color = '#BEBEBE', size = '1.2em' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    className="bi bi-circle-fill ml-1"
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" />
  </svg>
);

export default function Rate({
  initialRating,
  readonly = false,
  size,
  onHover,
  onChange
}) {
  return (
    <Rating
      readonly={readonly}
      initialRating={initialRating}
      onHover={onHover}
      onChange={onChange}
      emptySymbol={<RateIcon size={size} />}
      fullSymbol={<RateIcon color="#dc143c" size={size} />}
    />
  );
}
