import React from 'react';
import injectSheet from 'react-jss';

function AppLetters({ classes, size, color, style }) {
  const svgSize = size || 200;
  const width = svgSize;
  const height = svgSize / 5.591836734693878;

  return (
    <svg
      width={width}
      height={height}
      className={classes.container}
      viewBox="0 0 238 62">
      <path
        d="M5.818 48V8.728L21.712 48h2.358L39.896 8.728V48h5.81V1.31h-8.34L22.874 37.303 8.314 1.31H.007V48h5.81zm52.535 13.706c4.785-.068 8.1-2.347 9.946-6.836l16.919-40.674h-5.674l-11.245 27.72-11.211-27.72h-5.742l14.15 34.146-2.256 5.127c-.57 1.344-1.242 2.273-2.016 2.785-.775.513-1.778.77-3.008.77-1.071 0-2.028-.183-2.871-.547l-.786 4.75c1.07.32 2.335.48 3.794.48zm54.38-12.852c7.997 0 14.594-2.916 19.79-8.75V22.16H110.27v8.682h12.305v5.605c-1.048.957-2.466 1.795-4.255 2.512a14.87 14.87 0 01-5.589 1.077c-4.26 0-7.775-1.453-10.544-4.358-2.769-2.905-4.153-6.568-4.153-10.989 0-4.42 1.384-8.077 4.153-10.971 2.769-2.894 6.283-4.341 10.544-4.341 2.325 0 4.455.575 6.392 1.726 1.937 1.15 3.486 2.603 4.648 4.358l8.204-4.478a22.832 22.832 0 00-3.145-3.982c-1.185-1.196-2.558-2.295-4.119-3.298-1.56-1.002-3.366-1.783-5.417-2.341-2.05-.558-4.238-.838-6.563-.838-4.58 0-8.75.975-12.51 2.923s-6.767 4.78-9.023 8.493c-2.256 3.715-3.384 7.964-3.384 12.75 0 4.785 1.128 9.034 3.384 12.748 2.256 3.692 5.264 6.517 9.024 8.477 3.76 1.96 7.93 2.94 12.51 2.94zm38.417 0c2.552 0 4.837-.49 6.853-1.47 2.017-.98 3.698-2.198 5.042-3.656V48h8.887V14.196h-8.887v22.9c-.866 1.094-1.971 2.006-3.316 2.735-1.344.73-2.814 1.094-4.409 1.094-2.005 0-3.543-.479-4.614-1.436-1.07-.957-1.606-2.552-1.606-4.785V14.196h-8.887V38.19c0 3.373.917 5.993 2.751 7.862 1.835 1.868 4.563 2.802 8.186 2.802zM189.603 48V25.68c.729-1.048 1.925-1.953 3.588-2.717 1.664-.763 3.247-1.145 4.751-1.145 1.026 0 1.846.069 2.461.205v-8.681c-2.005 0-3.982.512-5.93 1.538-1.948 1.025-3.572 2.313-4.87 3.862v-4.546h-8.887V48h8.887zm27.24.854c2.553 0 4.837-.49 6.854-1.47 2.016-.98 3.697-2.198 5.041-3.656V48h8.887V14.196h-8.887v22.9c-.866 1.094-1.97 2.006-3.315 2.735-1.345.73-2.814 1.094-4.41 1.094-2.005 0-3.543-.479-4.614-1.436-1.07-.957-1.606-2.552-1.606-4.785V14.196h-8.887V38.19c0 3.373.917 5.993 2.752 7.862 1.834 1.868 4.563 2.802 8.186 2.802z"
        fill={color || '#fff'}
        fillRule="nonzero"
      />
    </svg>
  );
}

const styles = {
  container: { marginTop: 10, marginBottom: -3.5 }
};

export default injectSheet(styles)(AppLetters);
