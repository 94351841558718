import React, { useEffect, useState, useCallback } from 'react';

import CustomField from '../../../controls/CustomField';
import Button from '../../../controls/Button';
import { Form } from 'formik';

import { useTranslation } from 'react-i18next';
import injectSheet from 'react-jss';
import { PasswordAccessory } from '../../../controls/Accessory';
import LoadingIcon from '../../../controls/LoadingIcon';
import useCountries from '../../../hooks/useCountries';

import { US_ALPHA2_CODE } from '../../../constants';

const shouldUseCity = country => country !== US_ALPHA2_CODE;

function RegistrationForm({
  classes,
  values,
  setFieldValue,
  genders,
  isSubmitting,
  errors
}) {
  const { t } = useTranslation('remote');
  const [isPasswordHidden, setPasswordVisibility] = useState(true);
  const countries = useCountries();
  setFieldValue = useCallback(setFieldValue, []);

  const togglePasswordVisibility = () =>
    setPasswordVisibility(!isPasswordHidden);

  useEffect(() => {
    if (countries.length > 0) setFieldValue('country', countries[0].id);
  }, [setFieldValue, countries]);

  return (
    <Form className={classes.container}>
      <CustomField
        name="firstName"
        label={t('app.inputsFirstName')}
        special="*"
        errorMessage={errors.firstName}
      />
      <CustomField
        name="lastName"
        label={t('app.inputsLastName')}
        special="*"
        errorMessage={errors.lastName}
      />
      <CustomField
        name="gender"
        component="select"
        label={t('app.inputsGender')}
        errorMessage={errors.gender}
        special="*">
        {genders.map((country, key) => (
          <option key={key} value={country.id}>
            {country.label}
          </option>
        ))}
      </CustomField>
      <CustomField
        name="country"
        component="select"
        label={t('app.inputsCountry')}
        errorMessage={errors.country}
        special="*">
        {countries.map(({ id, label }, index) => (
          <option key={index} value={id}>
            {label}
          </option>
        ))}
      </CustomField>
      {shouldUseCity(values.country) ? (
        <CustomField
          name="city"
          label={t('app.inputsCity')}
          special="*"
          errorMessage={errors.city}
        />
      ) : (
        <CustomField
          name="zipcode"
          label={t('app.inputsZipcode')}
          special="*"
          errorMessage={errors.zipcode}
        />
      )}
      <CustomField
        type="email"
        name="email"
        label={t('app.inputsEmail')}
        special="*"
        errorMessage={errors.email}
      />
      <CustomField
        type={isPasswordHidden ? 'password' : 'default'}
        name="password"
        label={t('app.inputsPassword')}
        special="*"
        errorMessage={errors.password}
        Accessory={
          <PasswordAccessory
            onClick={togglePasswordVisibility}
            isSecondary={isPasswordHidden}
          />
        }
      />
      <Button
        type="submit"
        color="blue"
        icon={isSubmitting ? <LoadingIcon /> : null}
        disabled={isSubmitting ? true : false}>
        {t('userAuth.createAccount')}
      </Button>
    </Form>
  );
}

const styles = {
  '@global': {
    '*': {
      fontFamily: "'Fira Sans' !important"
    }
  },

  container: {
    margin: { left: 20, right: 20, bottom: 20 }
  }
};

export default injectSheet(styles)(RegistrationForm);
