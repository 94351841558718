import React, { Fragment } from 'react';

import Svg, { Path, G } from 'svgs';

// ViewBox
const { x, y, w, h } = { x: 0, y: 0, w: 24, h: 18 };

export const iconSize = h;

const SeePasswordIcon = ({ color, size, isSecondary }) => {
  const svgSize = size || h;

  const height = svgSize;
  const width = svgSize / (h / w);

  return (
    <Svg width={width} height={height} viewBox={`${x} ${y} ${w} ${h}`}>
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G transform="translate(0.000000, 1.000000)">
          <Path
            d="M20.4248,4.8244 C18.1212,2.5208 15.0584,1.252 11.8004,1.252 C8.5424,1.252 5.48,2.5204 3.1764,4.8244 L0,8.0004 L3.2552,11.2556 C5.5588,13.5592 8.6216,14.828 11.8796,14.828 C15.1376,14.828 18.2004,13.5596 20.504,11.2556 L23.6804,8.0792 L20.4248,4.8244 Z M19.938,10.69 C17.7856,12.8424 14.9236,14.028 11.8796,14.028 C8.8356,14.028 5.9736,12.8428 3.8212,10.69 L1.1316,8.0004 L3.7424,5.3896 C5.8948,3.2372 8.7568,2.0516 11.8008,2.0516 C14.8448,2.0516 17.7068,3.2368 19.8592,5.3896 L22.5488,8.0792 L19.938,10.69 Z"
            fill={color || '#000000'}
            fillRule="nonzero"
          />
          <Path
            d="M11.8288,2.828 C8.9616,2.828 6.6288,5.1608 6.6288,8.028 C6.6288,10.8952 8.9616,13.228 11.8288,13.228 C14.696,13.228 17.0288,10.8952 17.0288,8.028 C17.0288,5.1608 14.6964,2.828 11.8288,2.828 Z M11.8288,6.028 C10.726,6.028 9.8288,6.9252 9.8288,8.028 C9.8288,8.2488 9.6496,8.428 9.4288,8.428 C9.208,8.428 9.0288,8.2488 9.0288,8.028 C9.0288,6.484 10.2848,5.228 11.8288,5.228 C12.0496,5.228 12.2288,5.4072 12.2288,5.628 C12.2288,5.8488 12.05,6.028 11.8288,6.028 Z"
            fill={color || '#000000'}
            fillRule="nonzero"
          />
          {isSecondary && (
            <Fragment>
              <Path
                d="M9.5,15.5 L17.5,0.5"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="square"
              />
              <Path
                d="M8.5,15.5 L16.5,0.5"
                stroke={color || '#000000'}
                strokeWidth="2"
                strokeLinecap="square"
              />
            </Fragment>
          )}
        </G>
      </G>
    </Svg>
  );
};

export default SeePasswordIcon;
