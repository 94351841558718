import { createUseStyles } from 'react-jss';

const styles = createUseStyles(theme => ({
  box: {
    background: theme.white,
    padding: '20px',
    borderRadius: '10px',
    color: '#333',
    overflow: 'auto',
    marginBottom: '20px',
    boxShadow: '0px 0px 29px 0px rgba(0,0,0,0.15)',
    '&.green': {
      background: '#67C966',
      color: theme.white
    },
    '& img': {
      float: 'left',
      margin: '0 20px 5px 0'
    },
    '& p': {
      color: 'inherit !important',
      fontSize: '15px',
      lineHeight: '1.7em'
    }
  },
  link: {
    float: 'right'
  },
  content: {
    overflow: 'hidden',
    maxHeight: '145px',
    transition: 'all 0.4s ease-out'
  },
  collapsed: {
    maxHeight: '2000px',
    transition: 'all 0.4s ease-out'
  }
}));

export default styles;
