import { createUseStyles } from 'react-jss';

const styles = createUseStyles(theme => ({
  container: {
    backgroundColor: theme.white,
    borderRadius: '10px',
    color: '#333',
    height: '100%',
    overflow: 'hidden',
    boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.4)'
  },
  link: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    padding: '30px 20px',
    position: 'relative',
    '& img': {
      margin: '0 20px 5px 0'
    },
    '& h4': {
      margin: '0 !important',
      padding: '0 !important'
    }
  },
  cornerRibbon: {
    background: '#39d',
    borderRadius: '5px 0 0 0',
    bottom: 0,
    color: theme.white,
    fontSize: '0.8rem',
    overflow: 'hidden',
    position: 'absolute',
    padding: '0 5px',
    right: 0,
    textAlign: 'center'
  },

  default: { backgroundColor: theme.white },
  featured: { backgroundColor: 'rgb(0,200,100)' },
  featuredText: {
    color: theme.white,
    '&:hover': {
      color: theme.white
    }
  }
}));

export default styles;
