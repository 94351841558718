import { createUseStyles } from 'react-jss';

const styles = createUseStyles(theme => ({
  navigation: {
    backgroundColor: '#496bf9',
    '& a.link': {
      color: 'white',
      textDecoration: 'none !important',
      '&.active': {
        borderBottom: '3px solid white'
      }
    },
    '& .logo a': {
      display: 'inline-block',
      padding: '14px',
      '& img': {
        marginTop: '12px'
      }
    }
  },
  signInButton: {
    backgroundColor: 'white',
    color: '#496bf9'
  },
  logoutButton: {
    color: 'white',
    textDecoration: 'none !important',
    '&:hover': {
      color: 'white'
    }
  }
}));

export default styles;
