import axios from 'axios';
import humps from 'humps';

import CONFIG from '../config';
import { store } from '../model/model';
import history from '../history';
import { routing } from '../constants';

const axiosConfig = {
  baseURL: CONFIG.API_URL,
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ],
  transformRequest: [
    data => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest
  ]
};

/**
  Axios instance for rails app.

  Check out the documentation {@link https://github.com/axios/axios#custom-instance-defaults}
*/
const API = axios.create(axiosConfig);

API.interceptors.request.use(
  async request => {
    const {
      user: {
        currentUser: { token }
      }
    } = store.getState();

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  error => error
);

API.interceptors.response.use(
  success => success,
  error => {
    if (error.request.status === 403) {
      const {
        location: { pathname }
      } = history;

      const matchAuthPath = pathname.includes(routing.AUTH_PATH);
      if (!matchAuthPath) {
        store.getActions().user.logOutUser();
        history.push(routing.AUTH_PATH);
      }
    }

    return Promise.reject(error.response);
  }
);

export default API;
