import React from 'react';

const FacebookIcon = ({ color, ...props }) => {
  const svgSize = props.size || 20;

  return (
    <svg width={svgSize} height={svgSize} viewBox="0 0 13 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-305.000000, -480.000000)" fillRule="nonzero">
          <g transform="translate(20.000000, 469.000000)">
            <g fill={color || '#FFFFFF'}>
              <path d="M296.83677,11.4672207 L293.886571,11.4624872 C290.572124,11.4624872 288.430183,13.6600479 288.430183,17.061356 L288.430183,19.6428095 L285.46389,19.6428095 C285.207567,19.6428095 285,19.8506133 285,20.1069362 L285,23.8471681 C285,24.103491 285.207804,24.3110581 285.46389,24.3110581 L288.430183,24.3110581 L288.430183,33.7488539 C288.430183,34.0051768 288.63775,34.2127439 288.894073,34.2127439 L292.764241,34.2127439 C293.020564,34.2127439 293.228131,34.0049401 293.228131,33.7488539 L293.228131,24.3110581 L296.69642,24.3110581 C296.952742,24.3110581 297.16031,24.103491 297.16031,23.8471681 L297.16173,20.1069362 C297.16173,19.9838634 297.112737,19.8659974 297.025876,19.7788997 C296.939015,19.691802 296.820676,19.6428095 296.697603,19.6428095 L293.228131,19.6428095 L293.228131,17.4544792 C293.228131,16.4026795 293.478774,15.8687326 294.848906,15.8687326 L296.836297,15.8680226 C297.092383,15.8680226 297.29995,15.6602188 297.29995,15.4041325 L297.29995,11.9311108 C297.29995,11.6752612 297.09262,11.4676941 296.83677,11.4672207 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FacebookIcon;
