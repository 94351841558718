import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { routing } from '../constants';

import Sections from '../features/Application/Sections/Sections';
import ResourcesHome from '../features/Application/ResourceCategories';
import Resources from '../features/Application/Resources';
import ContentVisor from '../features/Application/ContentVisor';
import AvailableInApp from '../features/Application/AvailableInApp';

import Menu from '../controls/Menu';
import BrandFooter from '../controls/BrandFooter';

export default function ContentAppRouter() {
  const { path } = useRouteMatch();
  const match = useRouteMatch(`${routing.CONTENT_PATH}/visor`);

  return (
    <>
      {!match && <Menu />}
      <Switch>
        <Route exact path={`${path}/sections`} component={Sections} />
        <Route
          exact
          path={`${path}/resource-categories/:resourceType`}
          component={ResourcesHome}
        />
        <Route exact path={`${path}/resource/:id`} component={Resources} />
        <Route exact path={`${path}/visor/:id`} component={ContentVisor} />
        <Route exact path={`${path}/inapp/:id`} component={AvailableInApp} />
      </Switch>
      {!match && <BrandFooter />}
    </>
  );
}
