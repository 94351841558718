import React, { Fragment } from 'react';

import HTFooter from '../../assets/images/ht-logo-footer.png';
import CareerPreview from '../../assets/images/career-preview.png';
import AppStore from '../../assets/images/appstore.png';
import GooglePlay from '../../assets/images/googleplay.png';
import Facebook from '../../assets/images/facebook-icon.png';
import Instagram from '../../assets/images/instagram-icon.png';
import Twitter from '../../assets/images/twitter-icon.png';

import { useTranslation } from 'react-i18next';
import useAppLinks from '../../hooks/useAppLinks';
import useHome from './useHome';

function CareerCoach() {
  useHome();

  const { t } = useTranslation();
  const { goAppStore, goPlayStore } = useAppLinks();

  return (
    <Fragment>
      <section id="career" className="screens spots right">
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6">
              <h2 className="title">{t('home.career.title')}</h2>
              <div className="small-hr mt-1 mb-4" />
              <h4>
                {t('home.career.text1')}
                <br />
                <br />
                {t('home.career.text2')}
              </h4>
            </div>
            <div className="col-md-6 text-center reveal load-hidden">
              <img
                src={CareerPreview}
                width="350"
                className="img-fluid"
                alt="Career preview"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="download text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <p>{t('home.download.text1')}</p>
              <p className="text-big">{t('home.download.text2')}</p>
              <p>{t('home.download.text3')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4 text-center">
              <img
                src={AppStore}
                className="img-fluid app-button inline-block mr-2 my-2"
                alt="Appstore"
                onClick={goAppStore}
              />

              <img
                src={GooglePlay}
                className="img-fluid app-button inline-block"
                alt="Googleplay"
                onClick={goPlayStore}
              />
            </div>
          </div>
        </div>
      </section>
      <footer className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <a
                href="https://humantelligence.com"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={HTFooter}
                  width="194px"
                  className="img-fluid inline-block"
                  alt="Ht logo footer"
                />
              </a>
              <p className="text-small mt-3">{t('home.main.rights')}</p>
            </div>
            <div className="col-6 text-right">
              <a
                href="https://www.facebook.com/Humantelligence/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Facebook}
                  width="12px"
                  className="img-fluid inline-block mr-3"
                  alt="Facebook icon"
                />
              </a>
              <a
                href="https://www.instagram.com/humantelligence/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Instagram}
                  width="25px"
                  className="img-fluid inline-block mr-3"
                  alt="Instagram icon"
                />
              </a>
              <a
                href="https://twitter.com/humantelligence"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Twitter}
                  width="25px"
                  className="img-fluid inline-block"
                  alt="Twitter icon"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default CareerCoach;
