import React from 'react';

import injectSheet from 'react-jss';

function FlexContainer({ classes, flexGrow, paddingBottom, children }) {
  return (
    <div style={{ flexGrow, paddingBottom }}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
}

const styles = {
  inner: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default injectSheet(styles)(FlexContainer);
