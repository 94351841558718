import { createUseStyles } from 'react-jss';

const comingSoonStyles = createUseStyles({
  message: {
    color: 'white',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '26px'
  }
});

export default comingSoonStyles;
