import React from 'react';
import { Redirect, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { routing } from '../constants';

import PasswordReset from '../features/Authentication/PasswordReset';
import PasswordConfirmation from '../features/Authentication/PasswordConfirmation';
import Registration from '../features/Authentication/Registration';
import Login from '../features/Authentication/Login';
import Landing from '../features/Authentication/Landing';

export default function AuthenticationRouter() {
  const { path } = useRouteMatch();
  const { isLoggedIn } = useStoreState(store => store.user.currentUser);

  if (isLoggedIn) {
    return <Redirect to={`${routing.CONTENT_PATH}/sections`} />;
  }

  return (
    <Switch>
      <Route exact path={`${path}`} component={Landing} />
      <Route exact path={`${path}/login`} component={Login} />
      <Route exact path={`${path}/registration`} component={Registration} />
      <Route exact path={`${path}/forgot`} component={PasswordReset} />
      <Route
        exact
        path={`${path}/password_reset`}
        component={PasswordConfirmation}
      />
    </Switch>
  );
}
