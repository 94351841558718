import { createUseStyles } from 'react-jss';
import { sm } from '../../../helpers/responsiveHelper';

const sectionsStyles = createUseStyles({
  '@global': {
    body: {
      fontFamily: 'ProximaNovaRegular',
      fontSize: '18px',
      lineHeight: '25px',
      background: '#496bf9',
      [sm]: {
        paddingBottom: '70px'
      }
    },
    '.bg-white': {
      background: '#fff',
      color: '#333 !important'
    },
    'a:hover': {
      textDecoration: 'none'
    },
    h2: {
      fontSize: '32px',
      lineHeight: '44px',
      fontWeight: 600,
      [sm]: {
        fontSize: '30px',
        lineHeight: '40px'
      }
    },
    h4: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '500'
    }
  },
  container: {
    fontSize: '18px',
    lineHeight: '24px'
  },
  yellow: {
    color: '#ffee00'
  },
  appButtons: {
    paddingTop: '20px',
    [sm]: {
      background: '#496bf9',
      position: 'fixed',
      left: '0',
      bottom: '0',
      padding: '10px 0',
      width: '100%',
      zIndex: '999'
    }
  },
  appButton: {
    maxHeight: '54px',
    '&:hover': {
      cursor: 'pointer'
    },
    [sm]: {
      height: '50px'
    }
  }
});

export default sectionsStyles;
