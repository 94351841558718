import React, { useMemo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Formik } from 'formik';

import RegistrationForm from './RegistrationForm';
import TopBar from '../../../controls/TopBar';

import { routing, US_ALPHA2_CODE } from '../../../constants';
import getMobileOperatingSystem from '../../../helpers/platformDetection';

import { useRegisterUser } from '../../../hooks/userHooks';

function Registration({ history }) {
  const { t } = useTranslation(['remote']);

  const { status, isLoading, fireRequest, error } = useRegisterUser();
  const currentPlatform = useMemo(() => getMobileOperatingSystem(), []);
  const isDesktop =
    currentPlatform === 'windows' || currentPlatform === 'unknown';

  const GENDERS = [
    { id: 'male', label: t('app.gendersMale') },
    { id: 'female', label: t('app.gendersFemale') },
    { id: 'non_conforming', label: t('app.gendersNonConforming') }
  ];

  const initialValues = {
    firstName: '',
    lastName: '',
    gender: GENDERS[0].id,
    email: '',
    password: '',
    country: '',
    city: '',
    zipcode: ''
  };

  const goBack = () => history.goBack();

  const handleSubmit = async values => fireRequest(values);

  const validate = values => {
    const errors = {};

    Object.keys(initialValues).forEach(key => {
      if (!values[key]) errors[key] = t('app.validationsEmpty');
    });

    if (values.password.length < 8) {
      errors.password = t('app.validationsPassword');
    }

    if (values.country === US_ALPHA2_CODE) {
      delete errors.city;
    } else {
      delete errors.zipcode;
    }

    return errors;
  };

  const renderError = () =>
    !isEmpty(error) && (
      <div className="alert alert-danger" role="alert">
        {t('userAuth.errorGeneric')}
      </div>
    );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [error]);

  if (status === 201) {
    return (
      <Redirect
        to={isDesktop ? routing.CONTENT_HOME_PATH : routing.BASE_PATH}
      />
    );
  } else if (status === 200) {
    alert(
      t('userAuth.alreadyRegisteredHeader') +
        '\n' +
        t('userAuth.alreadyRegisteredText')
    );
    return <Redirect to={`${routing.AUTH_PATH}/login`} />;
  }

  return (
    <>
      <TopBar
        onBack={goBack}
        title={t('userAuth.topBarRegistration')}
        description={t('userAuth.completeInformation')}
      />
      <div className="container d-flex justify-content-center pt-3">
        <div className="col-md-8">
          {renderError()}
          <Formik
            initialValues={initialValues}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}>
            {({ values, errors, setFieldValue }) => (
              <RegistrationForm
                values={values}
                setFieldValue={setFieldValue}
                genders={GENDERS}
                isSubmitting={isLoading}
                errors={errors}
              />
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Registration;
