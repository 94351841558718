import { action } from 'easy-peasy';

const appsModel = {
  list: {},

  update: action((state, payload) => {
    state.list = payload;
  }),

  reset: action(state => {
    state.list = {};
  })
};

export default appsModel;
