import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import ComingSoonVector from '../vector/ComingSoonVector';
import comingSoonStyles from './styles';

export default function ComingSoon({ size = 300 }) {
  const { t } = useTranslation(['remote']);
  const styles = comingSoonStyles();
  const messageStyles = classNames('mt-4', styles.message);

  return (
    <>
      <ComingSoonVector size={size} />
      <div className={messageStyles}>
        {parse(t('resources.comingSoonDescription'))}
      </div>
    </>
  );
}
