import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions, useStoreRehydrated } from 'easy-peasy';
import Branch from 'branch-sdk';

import Preloader from './controls/Preloader';

export default function App({ children }) {
  const rehydrated = useStoreRehydrated();
  const updateBranchData = useStoreActions(
    actions => actions.branch.updateData
  );

  useEffect(() => {
    Branch.init(process.env.REACT_APP_BRANCH_KEY, (_err, data) => {
      if (!data) {
        return;
      }

      updateBranchData({
        ...data.data_parsed,
        referring_link: data.referring_link
      });
    });
  }, [updateBranchData]);

  return rehydrated ? children : <Preloader />;
}

App.propTypes = {
  children: PropTypes.node.isRequired
};
