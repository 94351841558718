import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { routing } from './constants';

import AuthenticationRouter from './routers/AuthenticationRouter';
import ContentAppRouter from './routers/ContentAppRouter';
import StaticContentRouter from './routers/StaticContentRouter';

import Dashboard from './features/User/Dashboard';
import PrivateRoute from './controls/PrivateRoute';

const ForgotPasswordRedirect = ({ location }) => (
  <Redirect to={{ ...location, pathname: `${routing.AUTH_PATH}/forgot` }} />
);
const PasswordResetRedirect = ({ location }) => (
  <Redirect
    to={{ ...location, pathname: `${routing.AUTH_PATH}/password_reset` }}
  />
);

export default function AppRouter() {
  const { AUTH_PATH, BASE_PATH, CONTENT_PATH } = routing;
  return (
    <>
      <Switch>
        <Route path={AUTH_PATH} component={AuthenticationRouter} />
        <PrivateRoute path={CONTENT_PATH} component={ContentAppRouter} />
        <PrivateRoute strict exact path={BASE_PATH} component={Dashboard} />
        <Route
          exact
          path={`${BASE_PATH}/forgot`}
          component={ForgotPasswordRedirect}
        />
        <Route
          exact
          path={`${BASE_PATH}/password_reset`}
          component={PasswordResetRedirect}
        />
        <Route path="/" component={StaticContentRouter} />

        <Redirect to="/" />
      </Switch>
    </>
  );
}
