import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';

import { routing } from '../../../constants';
import LoginForm from './LoginForm';
import TopBar from '../../../controls/TopBar';

import getMobileOperatingSystem from '../../../helpers/platformDetection';

import { useLoginUser } from '../../../hooks/userHooks';

function Login({ history }) {
  const { t } = useTranslation('remote');
  const { status, isLoading, fireRequest, error } = useLoginUser();
  const currentPlatform = useMemo(() => getMobileOperatingSystem(), []);
  const isDesktop =
    currentPlatform === 'windows' || currentPlatform === 'unknown';

  const initialValues = {
    email: '',
    password: ''
  };

  const goBack = () => history.goBack();

  const handleSubmit = async values =>
    fireRequest({
      password: values.password,
      email: values.email.toLocaleLowerCase()
    });

  const validate = values => {
    const errors = {};

    Object.keys(initialValues).forEach(key => {
      if (!values[key]) errors[key] = t('app.validationsEmpty');
    });

    return errors;
  };

  const renderError = () =>
    !isEmpty(error) && (
      <div className="alert alert-danger" role="alert">
        {error.status === 403
          ? t('app.validationsEmailOrPassword')
          : t('userAuth.errorGeneric')}
      </div>
    );

  if (status === 201) {
    return (
      <Redirect
        to={isDesktop ? routing.CONTENT_HOME_PATH : routing.BASE_PATH}
      />
    );
  }

  return (
    <>
      <TopBar onBack={goBack} title={t('userAuth.topBarLogin')} />
      <div className="container d-flex justify-content-center pt-3">
        <div className="col-md-8">
          {renderError()}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}>
            {({ values, setFieldValue, isSubmitting, errors }) => (
              <LoginForm
                values={values}
                isSubmitting={isLoading}
                errors={errors}
              />
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Login;
