import React, { cloneElement } from 'react';

import injectSheet from 'react-jss';
import SeePasswordIcon from './SeePasswordIcon';

function Accessory({ classes, onClick, size, Icon }) {
  return (
    <div className={classes.container} key={'rightInputAccessory'}>
      <button type="button" onClick={onClick} className={classes.button}>
        <Icon size={size || 16} />
      </button>
    </div>
  );
}

const styles = {
  container: {
    top: 0,
    bottom: 0,
    right: 5,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute'
  },

  button: {
    WebkitAppearance: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    paddingHorizontal: 40,
    margin: 0
  }
};

export const loadAccessory = (LoadAccessory, props) => {
  if (typeof LoadAccessory === 'function') return <LoadAccessory {...props} />;
  else if (typeof LoadAccessory === 'object')
    return cloneElement(LoadAccessory, props);
  else return null;
};

const FullAccessory = injectSheet(styles)(Accessory);

export const PasswordAccessory = ({ ...props }) => (
  <FullAccessory {...props} Icon={SeePasswordIcon} />
);

export default FullAccessory;
