import React from 'react';

import injectSheet from 'react-jss';

function OrText({ classes, children }) {
  return (
    <div className={classes.container}>
      <div className={classes.leftBar} />

      <h4 className={classes.text}>{children}</h4>

      <div className={classes.rightBar} />
    </div>
  );
}

const styles = {
  container: {
    marginTop: 35,
    marginBottom: 35,
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
    alignSelf: 'stretch',
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },

  leftBar: {
    backgroundColor: 'rgb(200,200,250)',
    height: 2,
    flex: 1,
    marginRight: 10
  },

  rightBar: {
    backgroundColor: 'rgb(200,200,250)',
    height: 2,
    flex: 1,
    marginLeft: 10
  },

  text: {
    fontSize: 20,
    color: '#FFF'
  }
};

export default injectSheet(styles)(OrText);
