import React, { useState } from 'react';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import injectSheet from 'react-jss';

import CustomField from '../../../controls/CustomField';
import Button from '../../../controls/Button';
import LoadingIcon from '../../../controls/LoadingIcon';

import { PasswordAccessory } from '../../../controls/Accessory';

function LoginForm({ classes, values, setFieldValue, isSubmitting, errors }) {
  const { t } = useTranslation();
  const [isPasswordHidden, setPasswordVisibility] = useState(true);

  const togglePasswordVisibility = () =>
    setPasswordVisibility(!isPasswordHidden);

  return (
    <Form className={classes.container}>
      <CustomField
        type="email"
        name="email"
        label={t('app.inputsEmail')}
        errorMessage={errors.email}
        special="*"
      />
      <CustomField
        type={isPasswordHidden ? 'password' : 'default'}
        name="password"
        label={t('app.inputsPassword')}
        errorMessage={errors.password}
        special="*"
        Accessory={
          <PasswordAccessory
            onClick={togglePasswordVisibility}
            isSecondary={isPasswordHidden}
          />
        }
      />

      <Button
        type="submit"
        color="blue"
        icon={isSubmitting ? <LoadingIcon /> : null}
        disabled={isSubmitting ? true : false}>
        {t('app.enter')}
      </Button>
    </Form>
  );
}

const styles = {
  '@global': {
    '*': {
      fontFamily: "'Fira Sans' !important"
    }
  },

  container: {
    margin: { left: 20, right: 20, bottom: 20 }
  }
};

export default injectSheet(styles)(LoginForm);
