const BASE_API_URL = `https://apiv2.humantelligence.io`;
const API_URL = `${BASE_API_URL}/v1/`;

export const DEFAULT_CONFIG = {
  dataset: { language: 'en', feature: 'default' }
};

// Get config vars from DOM
const reactVariables =
  document.getElementById('react-variables') || DEFAULT_CONFIG;

const CONFIG = {
  language: reactVariables.dataset.language,
  feature: reactVariables.dataset.feature,
  host: reactVariables.dataset.host,
  API_URL,
  BASE_API_URL
};

export default CONFIG;
