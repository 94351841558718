import React from 'react';

import { Field } from 'formik';

import classNames from 'classnames';
import injectSheet from 'react-jss';
import { loadAccessory } from './Accessory';

function CustomField({
  classes,
  type,
  component,
  name,
  label,
  children,
  special,
  errorMessage,
  required = false,
  Accessory
}) {
  const IconAccessory = loadAccessory(Accessory, {
    iconSize: 20
  });

  const inputClasses = classNames(classes.input, {
    [classes.select]: component === 'select'
  });

  const errorClases = classNames(classes.special, classes.error);

  return (
    <div className={classes.container}>
      <label className={classes.label} htmlFor={name}>
        {label}
        {special && <span className={classes.special}> {special}</span>}
      </label>

      <div className={classes.inputContainer}>
        <Field
          component={component}
          type={type}
          name={name}
          required={required}
          className={inputClasses}>
          {children}
        </Field>

        {IconAccessory}
      </div>

      {!!errorMessage && <p className={errorClases}>{errorMessage}</p>}
    </div>
  );
}

const styles = {
  container: {
    alignSelf: 'stretch',
    marginTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },

  label: {
    fontSize: 16,
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Fira Sans'
  },

  special: {
    fontSize: 16,
    color: 'rgb(248, 78, 90)',
    fontFamily: 'Fira Sans',
    fontWeight: 400
  },

  inputContainer: {
    position: 'relative'
  },

  input: {
    paddingBottom: 15,
    borderWidth: '0px 0px 1px',
    borderStyle: 'solid',
    fontSize: 17,
    width: '100%',
    fontFamily: 'Fira Sans',
    paddingTop: 5,
    color: 'rgb(0, 0, 0)',
    borderColor: 'rgb(150, 150, 150)'
  },

  select: {
    backgroundColor: 'transparent',
    borderRadius: '0px',
    WebkitAppearance: 'none',
    height: 50
  },

  error: {
    fontSize: 14,
    marginTop: 5
  }
};

export default injectSheet(styles)(CustomField);
