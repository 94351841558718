import API from './baseAPI';

export async function postRegistrations(data) {
  const response = await API.post('registrations', data);

  return response;
}

export async function postSessions(data) {
  const response = await API.post('sessions', data);

  return response;
}

export async function getPasswordResetNew(email) {
  const response = await API.get(`password_reset/new?email=${email}`);

  return response;
}

export async function patchPasswordReset(data) {
  const response = await API.patch('password_reset', data);

  return response;
}
