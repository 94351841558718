import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import HTFooter from '../assets/images/ht-logo-footer.png';
import Facebook from '../assets/images/facebook-icon.png';
import Instagram from '../assets/images/instagram-icon.png';
import Twitter from '../assets/images/twitter-icon.png';

function BrandFooter({ classes }) {
  const { t } = useTranslation();
  const footerClasses = classNames('mt-3 pt-5 pb-3', classes.footer);
  return (
    <footer className={footerClasses}>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <a
              href="https://humantelligence.com"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src={HTFooter}
                width="194px"
                className="img-fluid inline-block"
                alt="Ht logo footer"
              />
            </a>
            <p className="text-small mt-3">{t('home.main.rights')}</p>
          </div>
          <div className="col-6 text-right">
            <a
              href="https://www.facebook.com/Humantelligence/"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src={Facebook}
                width="12px"
                className="img-fluid inline-block mr-3"
                alt="Facebook icon"
              />
            </a>
            <a
              href="https://www.instagram.com/humantelligence/"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src={Instagram}
                width="25px"
                className="img-fluid inline-block mr-3"
                alt="Instagram icon"
              />
            </a>
            <a
              href="https://twitter.com/humantelligence"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src={Twitter}
                width="25px"
                className="img-fluid inline-block"
                alt="Twitter icon"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

const styles = {
  footer: {
    backgroundColor: 'white'
  }
};

export default injectSheet(styles)(BrandFooter);
