import { useState, useEffect, useCallback } from 'react';

//Our custom hook 'useAsyncData'

// Options:
// fetchFn (required): the function to execute to get data
// loadOnMount (opt): load the data on component mount
// clearDataOnLoad (opt): clear old data on new load regardless of success state
const useAsyncData = ({
  loadOnMount = false,
  clearDataOnLoad = false,
  fetchFn = null
} = {}) => {
  // Our data fetching state variables
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // A function to handle all the data fetching logic
  const fireRequest = useCallback(
    async event => {
      setIsLoading(true);
      setError();
      if (clearDataOnLoad === true) setData();

      try {
        const resp = await fetchFn(event);
        setData(resp);
        setIsLoading(false);
        return resp;
      } catch (e) {
        setError(e);
        setIsLoading(false);
        return e;
      }
    },
    [clearDataOnLoad, fetchFn]
  );

  // 'onMount'
  // maybe load the data if required
  useEffect(() => {
    if (loadOnMount && fetchFn !== null) fireRequest();
  }, [loadOnMount, fetchFn, fireRequest]);

  // Return the state and the load function to the component
  return { data, isLoading, error, fireRequest };
};

export default useAsyncData;
