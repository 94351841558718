import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

// Styles
import injectSheet from 'react-jss';

function Button({ classes, icon, color, children, onClick, type, disabled }) {
  const buttonClasses = classNames({
    [classes.button]: true,
    [classes[color]]: color
  });

  return (
    <div className={classes.container}>
      <button
        type={type}
        className={buttonClasses}
        onClick={onClick}
        disabled={disabled}>
        <div className={classes.text}>{children}</div>

        {icon && <div className={classes.icon}>{icon}</div>}
      </button>
    </div>
  );
}

Button.propTypes = {
  classes: PropTypes.object.isRequired
};

const buttonStyles = theme => ({
  container: {
    width: 'auto',
    minHeight: 50,
    alignSelf: 'stretch',
    marginTop: 16
  },

  button: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12,
    paddingBottom: 12,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'transparent'
  },

  text: {
    fontSize: 18,
    flex: '1 1 0%',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Fira Sans',
    fontWeight: 500
  },

  icon: {
    marginLeft: '-50px',
    marginRight: 30
  },

  green: {
    backgroundColor: theme.green
  },

  blue: {
    backgroundColor: theme.blue
  }
});

export default injectSheet(buttonStyles)(Button);
