import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';

const Thumbnail = ({ src }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <span style={{ width: '100px' }}>
      {!isImageLoaded && (
        <ContentLoader
          speed={2}
          width={100}
          height={50}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
        </ContentLoader>
      )}
      <img
        src={src}
        width={100}
        className={`rounded ${isImageLoaded ? '' : 'd-none'}`}
        alt=""
        onLoad={() => setTimeout(() => setIsImageLoaded(true), 1000)}
      />
    </span>
  );
};

export default Thumbnail;
