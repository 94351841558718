import React, { Fragment } from 'react';

import PasswordConfirmationForm from './PasswordConfirmationForm';
import { Formik } from 'formik';
import TopBar from '../../../controls/TopBar';

import { useTranslation } from 'react-i18next';
import { patchPasswordReset } from '../../../api/authenticationAPI';
import { routing } from '../../../constants';

function PasswordConfirmation({ history, location }) {
  const { t } = useTranslation();

  const goBack = () => history.goBack();

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!location.search) {
      setSubmitting(false);

      return null;
    }

    const paramSplit = location.search.split('?')[1].split('token=');

    if (!paramSplit || paramSplit.length < 2) return null;

    const resetToken = paramSplit[1];

    if (
      !!values.password &&
      !!values.passwordConfirmation &&
      values.password === values.passwordConfirmation
    ) {
      const data = {
        passwordReset: {
          token: resetToken,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation
        }
      };

      await patchPasswordReset(data);
    }

    setSubmitting(false);

    history.replace(routing.AUTH_PATH);
  };

  return (
    <Fragment>
      <TopBar onBack={goBack} title={t('userAuth.topBarResetPassword')} />

      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: ''
        }}
        onSubmit={handleSubmit}>
        {({ values }) => <PasswordConfirmationForm values={values} />}
      </Formik>
    </Fragment>
  );
}

export default PasswordConfirmation;
