import { action } from 'easy-peasy';

const branchModel = {
  data: {},

  updateData: action((state, payload) => {
    state.data = payload;
  })
};

export default branchModel;
