import React, { useState, useEffect } from 'react';
import groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getCategoryIcon } from '../../../helpers/categoryIcon';
import { routing } from '../../../constants';
import Card from '../../../controls/Card';
import useApps, { getAppI18n } from '../../../hooks/useApps';

import sectionsStyles from './styles';

export default function Sections() {
  sectionsStyles();

  const [myApps, setMyApps] = useState([]);
  const { t } = useTranslation(['remote']);
  const APPS = useApps();

  const loadApps = () => {
    const categories = groupBy(APPS, 'category');

    setMyApps(
      Object.keys(categories).reduce(
        (acc, category) => [
          ...acc,
          { label: category, apps: categories[category] }
        ],
        []
      )
    );
  };

  const renderResources = ({ id, comingSoon, isFeaturedApp }) => (
    <div key={id} className="col-md-4 pb-4">
      <Card
        link={
          isFeaturedApp
            ? `${routing.CONTENT_PATH}/inapp/${getAppI18n(id)}`
            : `${routing.CONTENT_PATH}/resource-categories/${id}`
        }
        type={isFeaturedApp ? 'featured' : 'default'}
        tagMessage={
          comingSoon
            ? t('auth.cardsTags.comingSoon')
            : isFeaturedApp
            ? t('auth.cardsTags.onlyApp')
            : null
        }>
        <img
          src={getCategoryIcon(id)}
          width={50}
          className="img-fluid"
          alt=""
        />
        <h4>{t(`auth.${getAppI18n(id)}Label`)}</h4>
      </Card>
    </div>
  );

  const renderSection = (item, index) => {
    const isFunSection = item.label === 'funStuff';
    const wrapperClass = classNames(
      'screens',
      {
        [`${index % 2 === 0 ? 'bubbles' : 'white'}`]: !isFunSection
      },
      { fun: isFunSection }
    );

    return (
      <section key={item.label} className={wrapperClass}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center mt-1">
                {t(`auth.${item.label}Label`)}
              </h2>
              <h4 className="text-left description">
                {t(`auth.${item.label}Description`)}
              </h4>
            </div>
          </div>
          <div className="row mt-4">{item.apps.map(renderResources)}</div>
        </div>
      </section>
    );
  };

  useEffect(loadApps, [APPS]);

  return <div>{myApps.map(renderSection)}</div>;
}
