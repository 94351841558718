export const xs = '@media (max-width: 575.98px)';
export const sm = '@media (max-width: 767.98px)';
export const md = '@media (max-width: 991.98px)';
export const lg = '@media (max-width: 1199.98px)';

export const min = {
  sm: '@media (min-width: 768px)',
  md: '@media (min-width: 992px)',
  lg: '@media (min-width: 1200px)'
};
