import React, { Fragment } from 'react';

import HTFooter from '../../assets/images/ht-logo-footer.png';

import Profile from '../../assets/images/profile.png';

import AppStore from '../../assets/images/appstore.png';
import GooglePlay from '../../assets/images/googleplay.png';
import Facebook from '../../assets/images/facebook-icon.png';
import Instagram from '../../assets/images/instagram-icon.png';
import Twitter from '../../assets/images/twitter-icon.png';

import Motivators from '../../assets/images/motivators.svg';
import Behaviors from '../../assets/images/behaviors.svg';
import Ideal from '../../assets/images/ideal.svg';

import { useTranslation } from 'react-i18next';
import useAppLinks from '../../hooks/useAppLinks';
import useHome from './useHome';

function MyProfile() {
  useHome();

  const { t } = useTranslation();
  const { goAppStore, goPlayStore } = useAppLinks();

  return (
    <Fragment>
      <section id="profile" className="screens spots">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center reveal load-hidden">
              <img
                src={Profile}
                width="471"
                className="img-fluid"
                alt="Profile"
              />
            </div>
            <div className="col-md-6">
              <h2 className="title">{t('home.profile.title')}</h2>
              <div className="small-hr small-hr mt-1 mb-4" />
              <h4>{t('home.profile.text1')}</h4>
              <ul className="mt-4">
                <li>{t('home.profile.list1')}</li>
                <li>{t('home.profile.list2')}</li>
                <li>{t('home.profile.list3')}</li>
                <li>{t('home.profile.list4')}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h2 className="title text-center">{t('home.intro.title')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-2 mt-3">
              <p className="text-center">{t('home.intro.text1')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 mt-5 mb-5 pb-5">
              <div className="card-deck">
                <div className="card align-items-center reveal load-hidden">
                  <img
                    src={Motivators}
                    width="180px"
                    className="inline-block img-fluid mt-5"
                    alt="Motivators"
                  />
                  <div className="card-body">
                    <h4 className="card-title text-center">
                      {t('home.intro.motivators')}
                    </h4>
                    <p>{t('home.intro.motivatorsText')}</p>
                  </div>
                </div>
                <div className="card align-items-center reveal load-hidden">
                  <img
                    src={Behaviors}
                    width="180px"
                    className="inline-block img-fluid mt-5"
                    alt="Behaviors"
                  />
                  <div className="card-body">
                    <h4 className="card-title text-center">
                      {t('home.intro.behaviors')}
                    </h4>
                    <p>{t('home.intro.behaviorsText')}</p>
                  </div>
                </div>
                <div className="card align-items-center reveal load-hidden">
                  <img
                    src={Ideal}
                    width="180px"
                    className="inline-block img-fluid mt-5"
                    alt="Ideal"
                  />
                  <div className="card-body">
                    <h4 className="card-title text-center">
                      {t('home.intro.ideal')}
                    </h4>
                    <p>{t('home.intro.idealText')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="science" className="science mt-5">
        <div className="container">
          <div className="row contents">
            <div className="col-md-12">
              <div className="float-content science-bg box-shadow mb-5">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="title text-center mb-5">
                      {t('home.science.title')}
                    </h2>
                  </div>
                  <div className="col-md-6 col-lg-5 offset-lg-1">
                    <p>{t('home.science.text1')}</p>
                  </div>
                  <div className="col-md-6 col-lg-5">
                    <p>{t('home.science.text2')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="download text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <p>{t('home.download.text1')}</p>
              <p className="text-big">{t('home.download.text2')}</p>
              <p>{t('home.download.text3')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4 text-center">
              <img
                src={AppStore}
                className="img-fluid app-button inline-block mr-2 my-2"
                alt="Appstore"
                onClick={goAppStore}
              />

              <img
                src={GooglePlay}
                className="img-fluid app-button inline-block"
                alt="Googleplay"
                onClick={goPlayStore}
              />
            </div>
          </div>
        </div>
      </section>
      <footer className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <a
                href="https://humantelligence.com"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={HTFooter}
                  width="194px"
                  className="img-fluid inline-block"
                  alt="Ht logo footer"
                />
              </a>
              <p className="text-small mt-3">{t('home.main.rights')}</p>
            </div>
            <div className="col-6 text-right">
              <a
                href="https://www.facebook.com/Humantelligence/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Facebook}
                  width="12px"
                  className="img-fluid inline-block mr-3"
                  alt="Facebook icon"
                />
              </a>
              <a
                href="https://www.instagram.com/humantelligence/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Instagram}
                  width="25px"
                  className="img-fluid inline-block mr-3"
                  alt="Instagram icon"
                />
              </a>
              <a
                href="https://twitter.com/humantelligence"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={Twitter}
                  width="25px"
                  className="img-fluid inline-block"
                  alt="Twitter icon"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default MyProfile;
