import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import Modal from './Modal';
import Rate from './Rate';

import API from '../api/baseAPI';

function RateModal({ resourceId, classes, onClose, isVisible }) {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successSaving, setSuccessSaving] = useState(false);
  const { t } = useTranslation(['remote']);

  const rateItLabel = value => {
    if ([1, 2, 3, 4, 5].includes(value)) {
      return t(`resources.rating.r${value}`);
    } else {
      return t(`resources.rateIt`);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const { status } = await API.post(
      `career_coach/resources/${resourceId}/review`,
      {
        review: { score: rating }
      }
    );

    setIsSubmitting(false);
    if ([200, 201].includes(status)) setSuccessSaving(true);
  };

  const handleOnClose = () => {
    onClose();
    setIsSubmitting(false);
  };

  useEffect(() => {
    async function fetchUserRating() {
      const {
        status,
        data: { data }
      } = await API.get(`career_coach/resources/${resourceId}/review`);

      if (status === 200) setRating(data.review.score);
    }

    if (isVisible) fetchUserRating();
  }, [resourceId, isVisible]);

  useEffect(() => {
    setHoverRating(rating);
  }, [rating]);

  const modalBodyClasses = classNames(
    'col-md-4 modal-content',
    classes.modalBody
  );

  const renderSuccess = () => (
    <div className="text-center py-3">
      <svg
        width="4em"
        height="4em"
        viewBox="0 0 16 16"
        className="bi bi-check-circle text-success"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        />
        <path
          fillRule="evenodd"
          d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
        />
      </svg>
      <h4 className="text-success py-2">
        {t('auth.resourceArticleRatingSuccess')}
      </h4>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={handleOnClose}>
        {t('app.closeSimple')}
      </button>
    </div>
  );

  const renderSubmitButton = () => (
    <button
      disabled={isSubmitting}
      type="button"
      className="btn btn-primary mt-3"
      onClick={handleSubmit}>
      <span>{t('app.save')}</span>
      {isSubmitting && (
        <span
          className="spinner-border spinner-border-sm ml-2"
          role="status"
          aria-hidden="true"
        />
      )}
    </button>
  );

  return (
    <Modal isVisible={isVisible} onClose={handleOnClose}>
      <div className={modalBodyClasses}>
        {successSaving ? (
          renderSuccess()
        ) : (
          <>
            <h4 className="text-center">How will you rate this resource?</h4>
            <div className="my-2">{rateItLabel(hoverRating)}</div>
            <Rate
              initialRating={rating}
              size="2em"
              onHover={hoverRating => setHoverRating(hoverRating || rating)}
              onChange={newRating => setRating(newRating)}
            />

            <div>
              <button
                type="button"
                className="btn btn-secondary mt-3 mr-3"
                onClick={handleOnClose}>
                {t('app.closeSimple')}
              </button>
              {renderSubmitButton()}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

const styles = {
  modalBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fefefe',
    margin: '15% auto',
    padding: '20px',
    border: '1px solid #888',
    borderRadius: '0.3rem'
  }
};

export default injectSheet(styles)(RateModal);
