import { useEffect } from 'react';
import homeStyles from './homeStyles';
import startScrollReveal from '../../assets/js/home.js';

const useHome = () => {
  homeStyles();

  useEffect(() => {
    startScrollReveal();
  }, []);
};

export default useHome;
