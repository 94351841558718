import React from 'react';

import Svg, { Path, Polyline, G } from 'svgs';

const ArrowLeftIcon = ({ size, color }) => {
  const svgSize = size || 25;

  const height = svgSize / 1.2666666666666666;
  const width = svgSize;

  return (
    <Svg width={width} height={height} viewBox="0 0 19 15">
      <G
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <G transform="translate(-275.000000, -16.000000)">
          <G transform="translate(276.000000, 17.000000)">
            <G transform="translate(8.500000, 6.500000) rotate(180.000000) translate(-8.500000, -6.500000)">
              <G stroke={color || '#000'}>
                <Polyline
                  strokeWidth="1.8"
                  fill={'rgba(0,0,0,0)'}
                  points="10.5238095 0.751739508 16.6784487 6.90637864 10.7434705 12.8413568"
                />
                <Path
                  d="M0.404761905,6.88095238 L15.8187423,6.88095238"
                  strokeWidth="1.8"
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default ArrowLeftIcon;
