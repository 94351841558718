import React from 'react';
import PropTypes from 'prop-types';

// Styles
import injectSheet from 'react-jss';

const preloaderStyles = theme => ({
  '@global': {
    body: {
      background: theme.blue
    },
    '@keyframes preloaderAnimation': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' }
    }
  },
  container: {
    textAlign: 'center',
    minHeight: '500px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  preloader: {
    display: 'inline-block',
    width: '64px',
    height: '64px',
    '&:after': {
      content: '" "',
      display: 'block',
      width: '46px',
      height: '46px',
      margin: '1px',
      borderRadius: '50%',
      border: `5px solid ${theme.white}`,
      borderColor: `${theme.white} transparent ${theme.white} transparent`,
      animation: 'preloaderAnimation 1.2s linear infinite'
    }
  }
});

function Preloader({ classes }) {
  return (
    <div className={classes.container}>
      <div className={classes.preloader} />
    </div>
  );
}

Preloader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectSheet(preloaderStyles)(Preloader);
