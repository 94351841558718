import { createUseStyles } from 'react-jss';

const contentVisorStyles = createUseStyles({
  '@global': {
    body: {
      background: '#496bf9',
      fontFamily: 'ProximaNovaRegular',
      fontSize: '18px',
      lineHeight: '25px'
    }
  },
  container: {
    background: 'white',
    height: '100vh'
  },
  ratingButtonContainer: {
    alignItems: 'center',
    background: '#496bf9',
    bottom: '1rem',
    borderRadius: '50%',
    boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.4)',
    display: 'flex',
    height: '60px',
    justifyContent: 'center',
    position: 'absolute',
    right: '1rem',
    width: '60px'
  }
});

export default contentVisorStyles;
