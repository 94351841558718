import BGSpots from '../../assets/images/bg-spots.png';
import Pattern from '../../assets/images/pattern.jpg';
import CheckGreen from '../../assets/images/check-green.png';
import DownloadBG from '../../assets/images/download-bg.jpg';
import ScienseBG from '../../assets/images/science-bg.jpg';
import ScienseBGSmall from '../../assets/images/science-bg-small.jpg';
import { createUseStyles } from 'react-jss';

const homeStyles = createUseStyles({
  '@global': {
    '.bg-group': {
      background: '#496bf9'
    },
    '.bg-friends': {
      background: '#1dafd1'
    },
    '.bg-career': {
      background: '#21b791'
    },
    '.bg-match': {
      background: '#7243f0'
    },
    '.bg-dating': {
      background: '#fa4e5a'
    },
    '.bg-couples': {
      background: '#f3796f'
    },
    '.bg-purple': {
      background: '#7243f0'
    },
    '.bg-remotework': {
      background: '#6f9af3'
    },
    '.purple': {
      color: '#7243f0'
    },
    '.yellow': {
      color: '#ffee00'
    },
    '.bg-white': {
      background: '#fff',
      color: '#333 !important'
    },
    '.bg-gray': {
      background: '#f3f6ff',
      color: '#333 !important'
    },
    'a:hover': {
      textDecoration: 'none'
    },
    h1: {
      fontSize: '36px',
      lineHeight: '48px',
      fontWeight: 600
    },
    h2: {
      fontSize: '48px',
      lineHeight: '44px',
      fontWeight: '600'
    },
    h4: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '500'
    },
    'ul.black li': {
      color: '#333333 !important'
    },
    body: {
      backgroundSize: '100% 950px',
      backgroundRepeat: 'no-repeat',
      fontFamily: 'ProximaNovaRegular',
      fontSize: '18px',
      lineHeight: '25px'
    },
    'body.small-header': {
      backgroundPositionY: '-655px',
      backgroundSize: '100% 900px'
    },
    '.header': {
      fontSize: '18px',
      lineHeight: '24px',
      background: '#496bf9'
    },
    '.logo a': {
      display: 'inline-block',
      padding: '14px'
    },
    '.slogan-margin': {
      marginTop: '12px'
    },
    '.intro .title': {
      marginTop: '100px'
    },
    '.features .title': {
      marginTop: '50px'
    },
    '.arrow-section': {
      background: '#fff',
      color: '#333',
      position: 'relative',
      boxShadow: '0px 11px 30px 3px rgba(0, 0, 0, 0.35)',
      '&:after': {
        top: '100%',
        left: ' 50%',
        border: 'solid transparent',
        content: '" "',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderColor: 'rgba(255, 255, 255, 0)',
        borderTopColor: '#fff',
        borderWidth: '30px',
        marginLeft: '-30px'
      }
    },
    '.screens': {
      padding: '2.5rem 0'
    },
    '.screens.spots': {
      backgroundImage: `url(${BGSpots})`,
      backgroundPosition: '20% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '435px 490px'
    },
    '.screens.spots.right': {
      backgroundPosition: '78% 50%'
    },
    '.screens.spots h2.title, .screens.spots h4': {
      color: '#3d3d3d'
    },
    '.screens.spots .small-hr': {
      borderColor: '#496bf9'
    },
    '.screens.bubbles': {
      backgroundColor: '#496bf9'
    },
    '.screens.white': {
      backgroundColor: 'white'
    },
    '.screens.dark': {
      backgroundColor: '#252525'
    },
    '.screens.shadow-inner':{
      boxShadow: 'inset 0 5px 50px -20px rgba(0,0,0,0.7)'
    },
    '.screens.dark h2.title, .screens.dark h4, .screens.dark li, .screens.dark p': {
      color: 'white'
    },
    '.screens.bubbles h2.title, .screens.bubbles h4, .screens.bubbles li, .screens.bubbles p': {
      color: 'white'
    },
    '.screens.bubbles .small-hr': {
      borderColor: '#47e1ba'
    },
    '.screens.gray': {
      background: '#f3f6ff'
    },
    '.screens.fun': {
      backgroundColor: '#7243f0',
      backgroundImage: `url(${Pattern})`,
      color: 'white'
    },
    '.screens.fun p, .screens.fun h2.title': {
      color: 'white'
    },
    '.screens ul li': {
      listStyleImage: `url(${CheckGreen})`,
      marginBottom: '1rem'
    },
    '.screens h2.title': {
      marginTop: '50px',
      fontSize: '36px'
    },
    '.screens h4': {
      fontSize: '20px'
    },
    '.download': {
      backgroundColor: '#4361dd',
      backgroundImage: `url(${DownloadBG})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '100px 0 150px 0',
      margin: '0 0 100px 0'
    },
    '.science': {
      backgroundColor: '#7243f0',
      backgroundImage: `url(${ScienseBG})`,
      backgroundSize: 'cover',
      color: 'white',
      fontSize: '20px',
      lineHeight: '30px',
      paddingBottom: '119px'
    },
    footer: {
      color: '#919191'
    },
    '.app-button': {
      maxHeight: '54px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '.card': {
      border: 'none !important',
      boxShadow: '-1px 0px 30px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      marginBottom: '20px !important'
    },
    '.card-columns': {
      columnCount: '4 !important'
    },
    '.hand:hover': {
      cursor: 'pointer'
    },
    '.small-hr': {
      width: '100px',
      border: '3px solid #7243f0',
      display: 'inline-block'
    },
    '.text-big': {
      fontSize: '48px',
      lineHeight: '48px'
    },
    '.text-small': {
      fontSize: '14px',
      lineHeight: '18px'
    },
    '.mt-80': {
      marginTop: '80px'
    },
    '.mt-40': {
      marginTop: '40px'
    },
    '.mt-10': {
      marginTop: '10px !important'
    },
    '.mt-0': {
      marginTop: '0px'
    },
    '.float-content': {
      marginTop: '-50px',
      padding: '156px 90px'
    },
    '.science-bg': {
      backgroundColor: '#7243f0',
      backgroundImage: `url(${ScienseBGSmall})`,
      backgroundSize: 'cover'
    },
    '.box-shadow': {
      boxShadow: '0px 25px 70px 4px rgba(0, 0, 0, 0.3)'
    },
    'html.sr .load-hidden': {
      visibility: 'hidden'
    },
    '.box': {
      background: '#ffffff',
      padding: '20px',
      borderRadius: '10px',
      color: '#333',
      overflow: 'auto',
      marginBottom: '20px',
      '&.green': {
        background: '#67C966',
        color: '#ffffff'
      },
      '& img': {
        float: 'left',
        margin: '0 20px 5px 0'
      },
      '& p': {
        color: 'inherit !important'
      }
    },
    '@media screen and (min-width: 1500px)': {
      body: {
        backgroundSize: '100% 900px'
      },
      'body.small-header': {
        backgroundSize: '100% 1100px'
      }
    },
    '@media screen and (max-width: 1199px)': {
      body: {
        backgroundSize: '100% 900px'
      },
      'body.small-header': {
        backgroundSize: '100% 800px'
      }
    },
    '@media screen and (max-width: 991px)': {
      '.float-content': {
        padding: '156px 50px'
      },
      body: {
        backgroundSize: '1500px 900px'
      }
    },
    '@media screen and (max-width: 767px)': {
      h1: {
        fontSize: '35px',
        lineHeight: '48px',
        textAlign: 'center'
      },
      h2: {
        fontSize: '35px',
        lineHeight: '40px'
      },
      body: {
        backgroundSize: '1600px 1000px'
      },
      'body.small-header': {
        backgroundSize: '1600px 850px'
      },
      '.intro .title': {
        marginTop: '0'
      },
      '.features .title': {
        marginTop: '0'
      },
      '.screens.spots': {
        backgroundImage: 'none'
      },
      '.download': {
        margin: '0 0 50px 0'
      },
      '.science': {
        paddingBottom: 0
      },
      '.card-columns': {
        columnCount: '1 !important'
      },
      '.app-buttons': {
        background: '#496bf9',
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        zIndex: '999'
      },
      '.app-button': {
        height: '50px'
      },
      '.text-big': {
        fontSize: '35px',
        lineHeight: '38px'
      },
      '.text-center-xs': {
        textAlign: 'center'
      },
      '.mt-80': {
        marginTop: '0'
      },
      '.float-content': {
        padding: '50px 20px'
      }
    },
    '.review-box':{
      background: '#ffffff',
      color: '#333333',
      boxShadow: '-1px 0px 30px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: 10,
      height: '100%',
      padding: 15,
      '& .title': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          width: 50,
          height: 50,
          marginRight: 5         
        },
        '& h4': {
          color: '#333333',
          fontWeight: 'bold' 
        }
      },
      '& .reviews': {
        margin: 0,
        '& .review': {
          border: '1px solid #ccc',
          padding: 15,
          marginTop: 10,
          borderRadius: 10,
          '& h5': {
            marginBottom: 3
          },
          '& p': {
            color: '#333333',
            marginBottom: 5
          },
          '& .star': {
            width: 20,
            height: 20
          }
        }
      }
    }
  }
});

export default homeStyles;
