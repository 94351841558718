import React, { useEffect, useMemo } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import injectSheet from 'react-jss';
import { isNil, upperCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import BackgroundVideo from './BackgroundVideo';
import FlexContainer from './FlexContainer';
import Button from '../../../controls/Button';
import AppLetters from '../../../controls/AppLetters';
import FacebookIcon from '../../../controls/Icon/FacebookIcon';
import Preloader from '../../../controls/Preloader';

import OrText from './OrText';
import getMobileOperatingSystem from '../../../helpers/platformDetection';
import { routing } from '../../../constants';

import useFacebook from '../../../hooks/useFacebook';

function Landing({ classes }) {
  const { t } = useTranslation(['translation', 'remote']);
  const { addToast } = useToasts();
  const { loginFb, status, isLoading, error } = useFacebook();
  const history = useHistory();

  const currentPlatform = useMemo(() => getMobileOperatingSystem(), []);

  const isDesktop =
    currentPlatform === 'windows' || currentPlatform === 'unknown';

  const goRegistration = () =>
    history.push(`${routing.AUTH_PATH}/registration`);
  const goLogin = () => history.push(`${routing.AUTH_PATH}/login`);

  const handleFBLogin = async user => {
    if (isNil(user) || user.status === 'unknown') return;
    loginFb(user);
  };

  useEffect(() => {
    if (error) {
      addToast(t('remote:userAuth.errorGeneric'), {
        appearance: 'error'
      });
    }
  }, [error, t, addToast]);

  if (isLoading) {
    return <Preloader />;
  }

  if (status === 201) {
    return (
      <Redirect
        to={isDesktop ? routing.CONTENT_HOME_PATH : routing.BASE_PATH}
      />
    );
  }

  return (
    <BackgroundVideo>
      <div className="container d-flex justify-content-center">
        <div className="col-md-6 pt-3">
          <FlexContainer paddingBottom="20px">
            <AppLetters size={300} />
            <p className={classes.slogan}>{t('remote:app.appSlogan')}</p>

            <p className={classes.registerToContinue} children="appSlogan">
              {t('userAuth.registerToContinue')}
            </p>

            <Button color="green" onClick={goRegistration}>
              {upperCase(t('userAuth.signUp'))}
            </Button>

            <OrText>{t('userAuth.registerOr')}</OrText>

            <FacebookLogin
              appId={process.env.REACT_APP_FB_APP_ID}
              fields="email,picture,first_name,last_name"
              callback={handleFBLogin}
              render={renderProps => (
                <div className={classes.fbButton} onClick={renderProps.onClick}>
                  <div className="px-3">
                    <FacebookIcon size="24" />
                  </div>
                  <p>{upperCase(t('remote:userAuth.facebookSignIn'))}</p>
                </div>
              )}
            />

            <p className={classes.doYouHaveAccount} children="appSlogan">
              {t('remote:userAuth.alreadyAUser')}
            </p>

            <Button color="blue" onClick={goLogin}>
              {upperCase(t('remote:userAuth.signIn'))}
            </Button>

            <p
              className={classes.termsAndPrivacy}
              dangerouslySetInnerHTML={{
                __html: t('app.termsAndPrivacy')
              }}></p>
          </FlexContainer>
        </div>
      </div>
    </BackgroundVideo>
  );
}

const styles = {
  slogan: {
    fontSize: 18,
    marginBottom: 40,
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Fira Sans',
    fontWeight: 400
  },

  registerToContinue: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
    width: 340,
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Fira Sans',
    fontWeight: 400
  },

  doYouHaveAccount: {
    fontSize: 20,
    marginBottom: 25,
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Fira Sans',
    fontWeight: 400
  },

  termsAndPrivacy: {
    marginTop: 20,
    marginBottom: 20,
    width: 360,
    fontSize: 17,
    fontFamily: 'Fira Sans',
    color: 'rgb(255, 255, 255)',
    textAlign: 'center',

    '& .link': {
      textDecoration: 'underline',
      color: 'rgb(255,255,255)'
    }
  },

  fbButton: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: '#5275BE',
    display: 'flex',
    minHeight: 50,
    marginBottom: 25,
    width: 'auto',

    '& div': {
      marginRight: '-60px'
    },

    '& p': {
      fontSize: 18,
      textAlign: 'center',
      color: 'rgb(255, 255, 255)',
      fontFamily: 'Fira Sans',
      fontWeight: 500,
      margin: 'auto !important'
    }
  }
};

export default injectSheet(styles)(Landing);
