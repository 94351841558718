import React from 'react';
import classNames from 'classnames';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { routing } from '../../constants';

import HTLogo from '../../assets/images/logo-ht-app.png';
import HTSlogan from '../../assets/images/humantelligence-slogan.png';

import menuStyles from './styles';

function Menu({ classes, onBack, title, description }) {
  const styles = menuStyles();

  const { isLoggedIn } = useStoreState(store => store.user.currentUser);
  const logOutUser = useStoreActions(store => store.user.logOutUser);
  const history = useHistory();
  const { t } = useTranslation(['remote']);
  const { pathname } = useLocation();

  const sectionsPath = routing.CONTENT_HOME_PATH;
  const isInSections = pathname === sectionsPath;

  const navContainerClasses = classNames([
    'navbar container d-flex flex-column flex-md-row align-items-center py-2',
    styles.navigation
  ]);
  const signInButtonClasses = classNames([
    'btn btn-outline-primary pb-2',
    styles.signInButton
  ]);
  const logoutButtonClasses = classNames([
    'btn btn-link pb-2 link',
    styles.logoutButton
  ]);
  const sectionsHomeClasses = classNames({
    'px-4 pb-2 link': true,
    active: isInSections
  });

  const logoutAndRedirect = e => {
    e.preventDefault();
    logOutUser();
    history.push('/');
  };

  return (
    <div className="bg-group">
      <div className={navContainerClasses}>
        <div className="my-0 mr-md-auto media">
          <div className="align-self-center media-left">
            <Link to={isLoggedIn ? sectionsPath : '/'}>
              <img
                src={HTLogo}
                className="mt-3"
                width="177"
                height="48"
                alt="Logo ht app"
              />
            </Link>
          </div>
          <div className="media-body logo">
            <Link to={isLoggedIn ? sectionsPath : '/'}>
              <img
                src={HTSlogan}
                width="147"
                height="28"
                alt="Humantelligence slogan"
              />
            </Link>
          </div>
        </div>
        <nav className="my-2 my-md-0 mr-md-3">
          {isLoggedIn && (
            <Link className={sectionsHomeClasses} to={sectionsPath}>
              {t('auth.navHome')}
            </Link>
          )}
          {isLoggedIn ? (
            <button className={logoutButtonClasses} onClick={logoutAndRedirect}>
              {t('auth.logout')}
            </button>
          ) : (
            <Link className={signInButtonClasses} to={routing.AUTH_PATH}>
              {t('userAuth.signIn')}
            </Link>
          )}
        </nav>
      </div>
    </div>
  );
}

export default Menu;
