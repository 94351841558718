import Branch from 'branch-sdk';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { postSessions, postRegistrations } from '../api/authenticationAPI';
import useAsyncData from './useAsyncData';

const logInAndSendBranchEvent = callback => () => {
  const {
    data: { status, data },
    isLoading,
    error,
    fireRequest
  } = useAsyncData({
    fetchFn: user => callback({ user })
  });

  const logInUser = useStoreActions(store => store.user.logInUser);
  const branchData = useStoreState(store => store.branch.data);

  if (status === 201) {
    logInUser({
      ...data.data.user,
      token: data.data.bearer
    });

    if (branchData && branchData.data) {
      const eventData = {
        user_id: data.data.user.id,
        user_email: data.data.user.email,
        store_pickup: 'unavailable'
      };

      Branch.logEvent(
        branchData.referer ? `SIGNUP/${branchData.referer}` : 'SIGNUP',
        eventData
      );
    }
  }

  return {
    fireRequest,
    status,
    data,
    isLoading,
    error
  };
};

export const useLoginUser = () => logInAndSendBranchEvent(postSessions)();

export const useRegisterUser = () =>
  logInAndSendBranchEvent(postRegistrations)();
