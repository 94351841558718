import React, { Fragment } from 'react';

import PasswordResetForm from './PasswordResetForm';
import { Formik } from 'formik';
import TopBar from '../../../controls/TopBar';

import { useTranslation } from 'react-i18next';
import { getPasswordResetNew } from '../../../api/authenticationAPI';
import { routing } from '../../../constants';

function PasswordReset({ history }) {
  const { t } = useTranslation();

  const goBack = () => history.goBack();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    await getPasswordResetNew(values.email);

    history.replace(routing.AUTH_PATH);
  };

  return (
    <Fragment>
      <TopBar onBack={goBack} title={t('userAuth.topBarResetPassword')} />

      <Formik
        initialValues={{
          email: ''
        }}
        onSubmit={handleSubmit}>
        {({ values }) => <PasswordResetForm values={values} />}
      </Formik>
    </Fragment>
  );
}

export default PasswordReset;
