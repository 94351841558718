import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import useAppLinks from '../../../hooks/useAppLinks';

import DescriptionWrapper from '../../../controls/DescriptionWrapper';
import HTApp from '../../../assets/images/ht-app-phone4-2x.png';
import AppStore from '../../../assets/images/appstore.png';
import GooglePlay from '../../../assets/images/googleplay.png';

import styles from './styles';

export default function Index() {
  const classes = styles();

  const { id } = useParams();
  const { t } = useTranslation(['translation', 'remote']);
  const { goAppStore, goPlayStore } = useAppLinks();

  const containerClass = classNames('container', classes.container);
  const title = t(`remote:auth.${id}Label`);
  const description = t(`remote:auth.${id}Description`);

  return (
    <DescriptionWrapper title={title} description={description}>
      {
        <div className={containerClass}>
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h2
                className={`mt-4 mt-md-2 pt-0 pt-md-2 mb-2 ${classes.yellow}`}>
                {t('remote:auth.cardsTags.onlyApp')}
              </h2>
              <h4 className="text-white mb-3 mt-4">
                {t('home.main.trademark')}
              </h4>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <img
                    src={HTApp}
                    className="img-fluid load-hidden"
                    alt="Ht app phone3 2x"
                  />
                </div>
              </div>
              <div className={classes.appButtons}>
                <div className="row">
                  <div className="col-sm-12 text-center-xs d-flex justify-content-center align-items-center">
                    <img
                      src={AppStore}
                      className={`img-fluid ${classes.appButton} inline-block mr-2`}
                      alt="Appstore"
                      onClick={goAppStore}
                    />
                    <img
                      src={GooglePlay}
                      className={`img-fluid ${classes.appButton} inline-block`}
                      alt="Googleplay"
                      onClick={goPlayStore}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </DescriptionWrapper>
  );
}
