import React from 'react';
import { ThemeProvider } from 'react-jss';
import PropTypes from 'prop-types';

const theme = {
  progressColor: '#454545',
  videoOpacity: 'rgba(75, 100, 225, 0.5)',

  green: 'rgb(10, 194, 145)',
  blue: 'rgb(75, 107, 249)',
  white: '#ffffff'
};

function AppThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

AppThemeProvider.propTypes = {
  children: PropTypes.node
};

export default AppThemeProvider;
