import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Iframe from 'react-iframe';
import queryString from 'query-string';

import TopBar from '../../../controls/TopBar';
import RateModal from '../../../controls/RateModal';
import RatingIcon from '../../../controls/Icon/RatingIcon';
import contentVisorStyle from './styles';

export default function ContentVisor() {
  const styles = contentVisorStyle();

  const [isRateModalVisible, setRateModalVisible] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();

  const { url, title = 'My Guru' } = queryString.parse(search);
  const goBack = () => history.goBack();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <TopBar onBack={goBack} title={title} />
      <Iframe
        url={url}
        width="100%"
        height="100%"
        display="initial"
        position="relative"
      />
      <div
        className={styles.ratingButtonContainer}
        onClick={() => setRateModalVisible(true)}>
        <RatingIcon size={45.5} />
      </div>
      <RateModal
        resourceId={id}
        isVisible={isRateModalVisible}
        onClose={() => setRateModalVisible(false)}
      />
    </div>
  );
}
