import i18n from 'i18next';
import axios from 'axios';

import CONFIG, { DEFAULT_CONFIG } from './config';

async function mergeRemoteLang(resources, lang) {
  const { status, data } = await axios.get(
    `${CONFIG.BASE_API_URL}/i18n/${lang}.json`
  );

  if (status === 200) return { ...resources, ...data };
  return { ...resources };
}

async function mergeLang(resources, lang) {
  const { status, data } = await axios.get(`/i18n/${lang}.json`);

  if (status === 200) return { ...resources, ...data };
  return { ...resources };
}

async function startI18n() {
  let resources = {};

  if (CONFIG.language === DEFAULT_CONFIG.dataset.language) {
    resources = await mergeLang(resources, CONFIG.language);
  } else {
    resources = await mergeLang(resources, DEFAULT_CONFIG.dataset.language);
    resources = await mergeLang(resources, CONFIG.language);
  }

  const resourcesRemote = await mergeRemoteLang(resources, CONFIG.language);

  i18n.init({
    resources: resources,
    lng: CONFIG.language,
    ns: ['translation'],
    fallbackLng: DEFAULT_CONFIG.dataset.language,
    debug: true,
    react: {
      wait: true
    },
    interpolation: {
      prefix: '{{',
      suffix: '}}'
    }
  });

  i18n.addResourceBundle('en', 'remote', resourcesRemote);
}

startI18n();

// This will trigger Suspense, we should put something cuter to load, but this is a start point
export default i18n;
