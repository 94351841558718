import { action, persist } from 'easy-peasy';
import API from '../api/baseAPI';

const initialState = {
  token: null,
  email: '',
  isLoggedIn: false
};

const userModel = persist(
  {
    currentUser: initialState,

    updateCurrentUser: action((state, payload) => {
      state.currentUser = { ...state.currentUser, ...payload };
    }),

    setCurrentUser: action((state, payload) => {
      state.currentUser = payload;
    }),

    resetUser: action(state => {
      state.currentUser = initialState;
    }),

    logInUser: action((state, payload) => {
      API.defaults.headers.common['Authorization'] = payload.token;
      state.currentUser = { ...payload, isLoggedIn: true };
    }),

    logOutUser: action(state => {
      API.defaults.headers.common['Authorization'] = null;
      state.currentUser = { ...initialState, token: null };
    })
  },
  {
    whitelist: ['currentUser'],
    storage: 'localStorage'
  }
);

export default userModel;
