/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './styles';

export default function IconBubble({
  color,
  icon,
  link,
  children,
  showViewMore = true
}) {
  const classes = styles();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const boxClasses = classNames({
    [classes.box]: true,
    [color]: true
  });

  const contentClasses = classNames({
    [classes.content]: true,
    [classes.collapsed]: show
  });

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      setShow(!show);
    },
    [show]
  );

  return (
    <div className={boxClasses}>
      <img src={icon} width="100" className="img-fluid" alt="" />
      <div className={contentClasses}>
        {children}
        {!!link && (
          <a href={link} className="btn btn-primary">
            {t('common.moreDetails')}
          </a>
        )}
      </div>
      {showViewMore && (
        <a href="#" className={classes.link} onClick={handleClick}>
          {!show ? t('common.viewMore') : t('common.hide')}
        </a>
      )}
    </div>
  );
}

IconBubble.propTypes = {
  color: PropTypes.string,
  link: PropTypes.string
};
